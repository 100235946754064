import { modalNames } from '../../store/modals/constants';
import { lazy } from 'react';
const FinalizeSeating = lazy(() => import('./FinalizeSeating'));
const ChargeQualifiedPlayers = lazy(() => import('./ChargeQualifiedPlayers'));
const RegisterEntryManually = lazy(() => import('./RegisterEntryManually'));
const RegisterEntryPayment = lazy(() => import('./RegisterEntryPayment'));
const ContactSponsor = lazy(() => import('./ContactSponsor'));
const ConfirmCancelTournament = lazy(() => import('./ConfirmCancelTournament'));
const ConfirmCancelEntry = lazy(() => import('./ConfirmCancelEntry'));
const EntryDetails = lazy(() => import('./EntryDetails'));
const InfoModal = lazy(() => import('./InfoModal'));
const HelpModal = lazy(() => import('./HelpModal'));

export const components: {
  name: keyof typeof modalNames;
  Component: React.FC;
}[] = [
  {
    name: modalNames.entryDetails,
    Component: EntryDetails,
  },
  {
    name: modalNames.confirmCancelEntry,
    Component: ConfirmCancelEntry,
  },

  {
    name: modalNames.confirmCancelTournament,
    Component: ConfirmCancelTournament,
  },
  {
    name: modalNames.infoModal,
    Component: InfoModal,
  },
  {
    name: modalNames.contactSponsor,
    Component: ContactSponsor,
  },
  {
    name: modalNames.registerEntryManually,
    Component: RegisterEntryManually,
  },
  {
    name: modalNames.registerEntryPayment,
    Component: RegisterEntryPayment,
  },
  {
    name: modalNames.finalizeSeating,
    Component: FinalizeSeating,
  },
  {
    name: modalNames.chargeQualifiedPlayers,
    Component: ChargeQualifiedPlayers,
  },
  {
    name: modalNames.helpModal,
    Component: HelpModal,
  },
];
