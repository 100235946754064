/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { isDev } from 'utils/isDev';
import { URL } from '../utils/Constants';
import store from '../store';
import { clearProfile } from '../store/profile/reducer';
import { SkipableOperationNamesOnError } from '../constants';

let client: ApolloClient<NormalizedCacheObject> | null = null;

export const AUTH_ERROR_MESSAGE = 'You are not authorized';

const initApollo = (showError) => {
  if (!client) {
    client = create(showError);
  }
  return client;
};

function create(showError) {
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        if (message.includes(AUTH_ERROR_MESSAGE)) {
          store.dispatch(clearProfile(null));
          alert('You access is expired. Please login again.');
        }

        if (isDev) {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
              locations
            )}, Path: ${path}`
          );
        }
      });

      const { operationName } = operation;
      const skipOperation = SkipableOperationNamesOnError.includes(operationName);
      if (!skipOperation) {
        return forward(operation);
      }
    }

    if (networkError) {
      showError(`[Network error]: ${networkError}`);
    }
  });

  const httpLink = createHttpLink({
    uri: URL.GraphQLEndpoint,
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: `Bearer ${store.getState().profile?.token}`,
      },
    }));
    return forward(operation);
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
    credentials: 'include',
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  });
}

export default initApollo;
