/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from './types';
import { ROLES } from './constants';

const initialState: IProfile = {
  memberName: '',
  memberId: 0,
  memberType: '',
  phoneNumber: '',
  first_name: '',
  last_name: '',
  email: '',
  total_masterpoints: 0,
  token: null,
  active: true,
  admin_id: null,
  last_logged_at: '',
  password: '',
  phone: '',
  status: '',
  role: ROLES.member,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    clearProfile: (state, action: PayloadAction<null>) => {
      return initialState;
    },
    updateProfile: (state, action: PayloadAction<IProfile>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { clearProfile, updateProfile } = profileSlice.actions;
export { initialState as profileInitialState };
export default profileSlice.reducer;
