import React from 'react';
import styled from 'styled-components';
import { space, variant } from 'styled-system';
import { variants, HeadingWrapper } from './Heading.styles';
import { COLORS } from 'styles/colors';
type HeadingProps = {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
  as?: React.ComponentType;
  weight?: 'thin' | 'normal' | 'bold';
  color?: keyof typeof COLORS | string;
  align?: 'center' | 'left' | 'right';
  margin?: string;
};

export const HeadingBase: React.FC<HeadingProps> = ({
  level,
  as: Component = `h${level}`,
  children,
  color = COLORS.BlueBlack,
  margin,
  align = 'left',
  weight = 'normal',
  ...props
}) => (
  <HeadingWrapper align={align}>
    <Component {...props} style={{ color, margin, fontWeight: `${weight}!important` }}>
      {children}
    </Component>
  </HeadingWrapper>
);

const Heading = styled(HeadingBase)(
  {
    margin: 0,
    fontWeight: 600,
    fontFamily: 'Roboto',
  },
  variant({
    variants,
    prop: 'level',
  }),
  variant({
    variants,
    prop: 'weight',
  }),
  space
);

export default Heading;
