import type { RootState } from '../index';
import { ROLES } from './constants';

export const selectProfile = (globalState: RootState) => {
  return globalState.profile;
};

export const selectIsAdminLoggedIn = (globalState: RootState) => {
  return globalState.profile.token && globalState.profile.role === ROLES.admin;
};
