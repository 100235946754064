import { FieldWrapper } from 'components/FieldWrapper';
import { DateInputWrapper, InputWrapper } from 'components/StayingHotel/styles/styles';
import { validationSchema } from 'components/StayingHotel/validation';
import { Formik } from 'formik';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { COLORS } from 'styles/colors';
import { ACBLButton } from 'uikit/Button';
import { Heading } from 'uikit/Heading';
import { Icon } from 'uikit/Icon';
import { Popup } from 'uikit/Popup';
import Select from 'uikit/Select/Select';
import { DEFAULT_TOUR_START_DATE_FORMAT } from 'uikit/TournamentCard/constants';
import { getTournamentAvailableDates } from 'utils/getTournamentAvailableDates';
import { TournamentDataType } from 'utils/Types';
import {
  PopupWrapper,
  PopupBodyWrapper,
  StepWrapper,
  ButtonsWrapper,
  StepBody,
  SelectInputDateWrapper,
} from './GoingPopup.styles';
import { CSSTransition } from 'react-transition-group';
import { ESavedTournamentStatus } from 'graphql/__generated__/graphql-global-types';
import { useHistory } from 'react-router';
import { TOURNAMENT_DETAIL_PAGE } from 'routes/routes.map';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectProfile } from '../../../store/profile/selectors';
import { modalNames } from '../../../store/modals/constants';
import { hideModal } from '../../../store/modals/reducer';
import FormErrorText from 'components/FormErrorText';
import { useSaveGoingPreRegForm } from '../../../graphql/hooks/useSaveGoingPreRegForm';
import { pxToRem } from 'utils/helpers';
type TProps = {
  tournament: TournamentDataType;
};

export const GoingPopup: React.FC<TProps> = ({ tournament }) => {
  const profile = useAppSelector(selectProfile);
  const dispatch = useAppDispatch();
  const handleClose = () => dispatch(hideModal({ name: modalNames.goingForm }));

  const { fetch, loading } = useSaveGoingPreRegForm();

  const history = useHistory();

  const [initialValues, setInitialValues] = useState({
    is_staying: false,
    start: moment(tournament.TourVStartDate).utc().format(DEFAULT_TOUR_START_DATE_FORMAT),
    end: moment(tournament.TourEndDate).utc().format(DEFAULT_TOUR_START_DATE_FORMAT),
  });

  const [isRegister, setIsRegister] = useState(false);
  const [isDecideLater, setIsDecideLater] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (initialValues.is_staying) {
      setIsRegister(false);
    }
  }, [initialValues.is_staying]);

  const availableDates = useMemo(
    () => getTournamentAvailableDates(tournament),
    [tournament.TourEndDate, tournament.TourVStartDate]
  );

  const getDefaultValue = useCallback((date: string) => {
    const value = moment(date).utc().format(DEFAULT_TOUR_START_DATE_FORMAT);
    return {
      id: uniqueId(),
      value,
    };
  }, []);

  const selectedDate = useCallback(
    (to: string, value: unknown, { handleSubmit, setFieldValue }) => {
      setFieldValue(to, value);
      setTimeout(() => {
        handleSubmit();
      }, 0);
    },
    []
  );

  const handleStayingInHotel = useCallback(() => {
    setInitialValues((prev) => ({
      ...prev,
      is_staying: true,
    }));
  }, [initialValues.is_staying]);

  const handleSubmit = useCallback(() => {
    setIsRegister(true);
    setIsDecideLater(true);
  }, []);

  const handleDecideLater = useCallback(() => {
    setIsRegister(true);
    setIsSubmit(true);
  }, []);

  const handleRegister = useCallback(
    async (isRedirect = true) => {
      const { data } = await fetch({
        variables: {
          memberId: profile.memberId,
          tournaments: [
            {
              host_hotel: initialValues.is_staying,
              host_hotel_check_in_date: initialValues.start,
              host_hotel_check_out_date: initialValues.end,
              tour_id: tournament.TourID,
              status: ESavedTournamentStatus.GOING,
            },
          ],
        },
      });

      if (isRedirect && data && data.saveTournament?.ok) {
        history.push(TOURNAMENT_DETAIL_PAGE.replace(':id', tournament.TourID.toString()));
      }

      handleClose();
    },
    [initialValues, profile, tournament, history]
  );

  return (
    <PopupWrapper>
      <Popup
        overflow='auto'
        onClose={handleClose}
        shouldCloseOnOverlayClick={false}
        hideCloseButton={false}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={setInitialValues}
          validationSchema={validationSchema}
        >
          {(formProps) => (
            <PopupBodyWrapper>
              <Heading level={3} align='center' color={COLORS.BlueDarkActive}>
                Thanks for letting us know you are going <br /> to visit the {tournament.TourName}{' '}
                Tournament.
              </Heading>
              <StepWrapper>
                <Heading color={COLORS.BlueDarkActive} level={5}>
                  Will you be staying at the host hotel?
                </Heading>
                <StepBody>
                  <ButtonsWrapper>
                    <ACBLButton
                      disabled={initialValues.is_staying}
                      variant={initialValues.is_staying ? 'outline-disabled' : 'outline'}
                      size='sm'
                      height={pxToRem(38)}
                      onClick={() => setIsRegister(true)}
                    >
                      NO
                    </ACBLButton>

                    <ACBLButton
                      disabled={initialValues.is_staying}
                      variant='primary'
                      size='sm'
                      height={pxToRem(38)}
                      onClick={handleStayingInHotel}
                    >
                      YES
                    </ACBLButton>
                  </ButtonsWrapper>
                </StepBody>
                <span className='disclaimer__text'>
                  * This is not a hotel reservation. Your selection here helps tournament sponsors
                  plan ahead.
                </span>
              </StepWrapper>

              <CSSTransition
                in={initialValues.is_staying}
                timeout={200}
                classNames='slideDown'
                unmountOnExit
              >
                <StepWrapper>
                  <Heading color={COLORS.BlueDarkActive} level={5}>
                    Which nights are you going to stay?
                  </Heading>
                  <StepBody>
                    <SelectInputDateWrapper>
                      <InputWrapper>
                        <DateInputWrapper>
                          <FieldWrapper name='start'>
                            {(props) => (
                              <Select
                                showCross={false}
                                items={availableDates}
                                defaultValue={getDefaultValue(tournament.TourVStartDate)}
                                selectedItem='value'
                                setValue={(date) => selectedDate('start', date, formProps)}
                                {...props}
                              />
                            )}
                          </FieldWrapper>
                        </DateInputWrapper>
                        <Icon icon='defis' alt='defis' size={14} />
                        <DateInputWrapper>
                          <FieldWrapper name='end'>
                            {(props) => (
                              <Select
                                showCross={false}
                                items={availableDates}
                                defaultValue={getDefaultValue(tournament.TourEndDate)}
                                selectedItem='value'
                                setValue={(date) => selectedDate('end', date, formProps)}
                                {...props}
                              />
                            )}
                          </FieldWrapper>
                        </DateInputWrapper>
                      </InputWrapper>
                      {formProps.errors.end && formProps.touched.start ? (
                        <FormErrorText error={formProps.errors.end} />
                      ) : null}
                    </SelectInputDateWrapper>
                    <ButtonsWrapper>
                      <ACBLButton
                        disabled={isDecideLater}
                        variant={isDecideLater ? 'outline-disabled' : 'outline'}
                        size='sm'
                        height={pxToRem(38)}
                        onClick={handleDecideLater}
                      >
                        DECLARE LATER
                      </ACBLButton>
                      <ACBLButton
                        disabled={!formProps.isValid || isSubmit}
                        variant='primary'
                        size='sm'
                        height={pxToRem(38)}
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </ACBLButton>
                    </ButtonsWrapper>
                  </StepBody>
                </StepWrapper>
              </CSSTransition>

              <CSSTransition in={isRegister} unmountOnExit timeout={200} classNames='slideDown'>
                <StepWrapper>
                  <Heading color={COLORS.BlueDarkActive} level={5}>
                    Would you like to register for the events?
                  </Heading>
                  <StepBody>
                    <ButtonsWrapper>
                      <ACBLButton
                        disabled={loading}
                        variant={loading ? 'outline-disabled' : 'outline'}
                        size='sm'
                        height={pxToRem(38)}
                        onClick={() => handleRegister(false)}
                      >
                        NO, THANKS
                      </ACBLButton>
                      <ACBLButton
                        disabled={loading}
                        variant='orange'
                        size='sm'
                        height={pxToRem(38)}
                        onClick={handleRegister}
                      >
                        REGISTER NOW
                      </ACBLButton>
                    </ButtonsWrapper>
                  </StepBody>
                </StepWrapper>
              </CSSTransition>
            </PopupBodyWrapper>
          )}
        </Formik>
      </Popup>
    </PopupWrapper>
  );
};
