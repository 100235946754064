import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { VIEWPORTS } from 'styles/responsive.styles';
import { slideDown } from 'styles/transitions';
import { StyledDropdown, StyledSelect } from 'uikit/Select/styles';
import { pxToRem } from 'utils/helpers';

export const PopupWrapper = styled.div`
  z-index: 14;
  position: relative;
`;

export const PopupBodyWrapper = styled.div`
  padding: ${pxToRem(40)} ${pxToRem(50)};

  @media ${VIEWPORTS.Mobile} {
    padding-left: 0;
    padding-right: 0;
  }

  ${slideDown}
`;

export const StepWrapper = styled.div`
  margin: ${pxToRem(40)} 0;
`;

export const StepBody = styled.div`
  margin: ${pxToRem(20)} 0;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxToRem(40)};
  max-width: ${pxToRem(400)};
`;

export const SelectInputDateWrapper = styled.div`
  max-width: ${pxToRem(340)};
  margin-bottom: ${pxToRem(20)};
  ${StyledSelect} {
    border: ${pxToRem(1)} solid ${COLORS.BorderGrey}!important;
    &[open] {
      border-bottom: 0 !important;
    }
  }

  ${StyledDropdown} {
    border: ${pxToRem(1)} solid ${COLORS.BorderGrey}!important;
  }

  @media ${VIEWPORTS.Mobile} {
    max-width: 100%;
    width: 100%;
  }
`;
