import regional from 'assets/icons/regional.svg';
import sectional from 'assets/icons/sectional.svg';
import nabc from 'assets/icons/nabc.svg';
import others from 'assets/icons/others.svg';
import gnt from 'assets/icons/gnt.svg';
import heart from 'assets/icons/heart.svg';
import search from 'assets/icons/search.svg';
import searchWhite from 'assets/icons/search-white.svg';
import plus from 'assets/icons/plus.svg';
import minus from 'assets/icons/minus.svg';
import arrowBottom from 'assets/icons/arrow-bottom.svg';
import arrowBottomActive from 'assets/icons/arrow-bottom-active.svg';
import tourType from 'assets/icons/tour-type.svg';
import phone from 'assets/icons/phone.svg';
import phoneBlue from 'assets/icons/phoneBlue.svg';
import location from 'assets/icons/location.svg';
import locationBlue from 'assets/icons/location-blue.svg';
import loopWhite from 'assets/icons/loop-white.svg';
import district from 'assets/icons/district.svg';
import districtBlue from 'assets/icons/district-blue.svg';
import pair from 'assets/icons/pair-new.svg';
import team from 'assets/icons/team-new.svg';
import cross from 'assets/icons/cross.svg';
import trash from 'assets/icons/trash.svg';
import masterpoint from 'assets/icons/masterpoint.svg';
import defis from 'assets/icons/defis.svg';
import calendar from 'assets/icons/calendar.svg';
import person from 'assets/icons/person.svg';
import registered from 'assets/icons/registered.svg';
import download from 'assets/icons/download.svg';
import check from 'assets/icons/check.svg';
import checkGreen from 'assets/icons/checkGreen.svg';
import info from 'assets/icons/info.svg';
import infoRed from 'assets/icons/infoRed.svg';
import filterby from 'assets/icons/filterby.svg';
import loopGrey from 'assets/icons/search-grey.svg';
import left from 'assets/icons/left.svg';
import assignedSeats from 'assets/icons/assigned_seats.svg';
import electricPlug from 'assets/icons/electric_plug.svg';
import ticket from 'assets/icons/ticket.svg';
import pairNew from 'assets/icons/pair-new.svg';
import teamNew from 'assets/icons/team-new.svg';
import circleMenu from 'assets/icons/circled-menu.png';
import gridViewIconWhite from 'assets/icons/grid-view-white.svg';
import listViewIconBlue from 'assets/icons/list-view-blue.svg';
import gridViewIconBlue from 'assets/icons/grid-view-blue.svg';
import listViewIconWhite from 'assets/icons/list-view-white.svg';
import heartRed from 'assets/icons/heart-active.svg';
import canceledTicket from 'assets/icons/canceled-ticket.svg';
import email from 'assets/icons/email.svg';
import password from 'assets/icons/password.svg';
import logoAmex from 'assets/icons/credit_cards_logo_amex.svg';
import logoDiscover from 'assets/icons/credit_cards_logo_discover.svg';
import masterCard from 'assets/icons/mastercard.svg';
import visaCard from 'assets/icons/visa.svg';
import plusWhite from 'assets/icons/plus-white.svg';
import minusWhite from 'assets/icons/minus-white.svg';
import menuDots from 'assets/icons/menu-dots.svg';
import pencil from 'assets/icons/pencil.svg';
import pencilDisabled from 'assets/icons/pencil-disabled.svg';
import starFull from 'assets/icons/star.png';
import starEmpty from 'assets/icons/star-empty.png';
import ticketFull from 'assets/icons/ticket.png';
import ticketEmpty from 'assets/icons/ticket-empty.png';
import ticketEmptyWhite from 'assets/icons/ticket-empty-white.svg';
import goingFull from 'assets/icons/going.png';
import goingEmpty from 'assets/icons/going-empty.png';
import checkWhite from 'assets/icons/checkWhite.svg';
import pdf from 'assets/icons/pdf.svg';
import pdfBlue from 'assets/icons/pdf-blue.svg';
import link from 'assets/icons/link.svg';
import linkBlue from 'assets/icons/link-blue.svg';
import flyerDownload from 'assets/icons/flyer-download.svg';
import earth from 'assets/icons/earth.svg';
import earthBlue from 'assets/icons/earth-blue.svg';
import hotel from 'assets/icons/hotel.svg';
import hotelBlue from 'assets/icons/hotelBlue.svg';
import print from 'assets/icons/print.svg';
import headerLogo from 'assets/icons/header-logo.svg';
import infoBlue from 'assets/icons/infoBlue.svg';
import infoRedFilled from 'assets/icons/infoRedFilled.svg';
import filledArrow from 'assets/icons/arrow-filled.svg';
import personBlue from 'assets/icons/person-blue.svg';
import trashNeutral from 'assets/icons/trash-neutral-color.svg';
import itemDiscount from 'assets/icons/discount-symbol.svg';
export const ICON_NAMES = Object.freeze({
  itemDiscount,
  trashNeutral,
  trash,
  earthBlue,
  linkBlue,
  infoBlue,
  hotel,
  hotelBlue,
  infoRedFilled,
  earth,
  listViewIconBlue,
  plusWhite,
  pdf,
  pdfBlue,
  pencil,
  districtBlue,
  flyerDownload,
  link,
  pencilDisabled,
  heartRed,
  gridViewIconWhite,
  checkWhite,
  listViewIconWhite,
  minusWhite,
  gridViewIconBlue,
  circleMenu,
  cross,
  calendar,
  pairNew,
  teamNew,
  filterby,
  loopGrey,
  defis,
  left,
  masterpoint,
  regional,
  locationBlue,
  team,
  minus,
  pair,
  tourType,
  location,
  district,
  loopWhite,
  phone,
  sectional,
  others,
  nabc,
  gnt,
  heart,
  search,
  plus,
  arrowBottom,
  arrowBottomActive,
  person,
  registered,
  download,
  check,
  checkGreen,
  info,
  infoRed,
  assignedSeats,
  electricPlug,
  ticket,
  searchWhite,
  canceledTicket,
  email,
  password,
  logoAmex,
  logoDiscover,
  masterCard,
  visaCard,
  menuDots,
  starFull,
  starEmpty,
  ticketFull,
  ticketEmpty,
  ticketEmptyWhite,
  goingFull,
  goingEmpty,
  print,
  headerLogo,
  phoneBlue,
  filledArrow,
  personBlue,
});
