/*eslint-disable*/
import React from 'react';
import { Button, Grid, Box, AppBar, Toolbar, Link } from '@material-ui/core';

// core components
import { useStyles } from '../Styling/DefaultFooterStyles';

import ACBLlogo from '../../assets/img/ACBL_Logo_Primary_White_Primary_White.png';
import BaronClay from '../../assets/img/bb-reverse-offical-01.png';
import EmailIcon from '@material-ui/icons/Email';
import MapIcon from '@material-ui/icons/Map';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';

export default function DefaultFooter() {
  const classes = useStyles();

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      spacing={10}
      justifyContent='center'
      className={classes.largeGroup}
    >
      <Grid item className={classes.footerItem}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          className={classes.navLinks}
        >
          <Grid item>
            <Link
              className={''}
              component='button'
              variant='body2'
              onClick={() => {
                window.location.href = '';
              }}
              name='Contact Us'
            >
              <EmailIcon className={classes.navButtons} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={''}
              component='button'
              variant='body2'
              onClick={() => {
                window.open('https://www.acbl.org/contact/')
                // window.location.href = 'https://www.acbl.org/contact/';
              }}
              target='_blank'
              name='Contact Us'
            >
              <Typography>
                <span className={classes.navButtonText}>Contact Us</span>
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          className={classes.navLinks}
        >
          <Grid item>
            <Link
              className={''}
              component='button'
              variant='body2'
              onClick={() => {
                window.location.href = '';
              }}
              name='Address'
            >
              <MapIcon className={classes.navButtons} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              className={''}
              component='button'
              variant='body2'
              onClick={() => {
                window.open('https://g.page/ACBLbridge')
              }}
              name='Address'
            >
              <Typography>
                <span className={classes.navButtonText}>
                  6575 Windchase Blvd
                  <br />
                  Horn Lake, MS 38637
                </span>
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          className={classes.navLinks}
        >
          <Grid item>
            <Link
              className={''}
              component='button'
              variant='body2'
              onClick={() => {
                window.location.href = '';
              }}
              name='Phone Number'
            >
              <PhoneIcon className={classes.navButtons} />
            </Link>
          </Grid>
          <Grid item>
            <Typography>
              <a href='tel:662-253-3100' className={classes.navButtonText}>
                662-253-3100
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.footerItem}>
        <Grid item>
          <img src={ACBLlogo} alt='ACBL_Logo' className={classes.acblLogo} />
        </Grid>
        <Grid container direction='row' alignItems='center' justifyContent='center'>
          <Grid item>
            <Link
              className={classes.navText}
              component='button'
              variant='body2'
              onClick={() => {
                window.location.href =
                  'https://s3.amazonaws.com/cdn.acbl.org/wp-content/uploads/2014/02/privacy_policy.pdf';
              }}
            >
              <Typography variant='caption'>Privacy Policy</Typography>
            </Link>
          </Grid>
          &nbsp;
          <Grid item>
            <Link
              className={classes.navText}
              component='button'
              variant='body2'
              onClick={() => {
                window.location.href =
                  'https://s3.amazonaws.com/cdn.acbl.org/wp-content/uploads/2014/02/termsofuse.pdf';
              }}
            >
              <Typography variant='caption'>• Terms of Use</Typography>
            </Link>
          </Grid>
          &nbsp;
          <Grid item>
            <Link
              className={classes.navText}
              component='button'
              variant='body2'
              onClick={() => {
                window.location.href =
                  'https://www.acbl.org/about-acbl/administration/binding-arbitration/';
              }}
            >
              <Typography variant='caption'>• Arbitration Agreement</Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.footerItem}>
        <img src={BaronClay} alt='Baron_Clay' className={classes.baron} />
      </Grid>
    </Grid>
  );
}
