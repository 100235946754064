import { gql } from '@apollo/client';

export const createPreRegistrationPaymentMutation = gql`
  mutation createPayment($payload: PreRegistrationPaymentInput!) {
    createPayment(payload: $payload) {
      id
      member_number
      tour_id
      payment_id
      total_price
      currency
      status
      location_id
      registration_id
      events
    }
  }
`;

export const createRegistrationMutation = gql`
  mutation createRegistration(
    $registrationPayload: RegistrationInput
    $paymentPayload: PreRegistrationPaymentInput
    $sendEmail: Boolean
  ) {
    createRegistration(
      registrationPayload: $registrationPayload
      paymentPayload: $paymentPayload
      sendEmail: $sendEmail
    ) {
      paymentResponse {
        payment_id
        status
      }
      registrationResponse {
        registration_id
        tour_id
        tour_name
        payment_id
      }
    }
  }
`;

export const updateTournamentMutation = gql`
  mutation updateTournament($active_updated_by: Int!, $payload: SchTourneyInput!, $tour_id: Int!) {
    updateTournament(active_updated_by: $active_updated_by, payload: $payload, tour_id: $tour_id) {
      Active: active
    }
  }
`;

export const cancelRegisteredEntriesMutation = gql`
  mutation cancelEntries($payload: CancelEntriesInput!) {
    cancelEntries(payload: $payload) {
      registered_entries_id
      registration_id
      tour_id
      event_id
      event_date
      event_name
      status
      canceled_at
      refunded_at
      refund_id
      refund_status
    }
  }
`;

export const adminCancelEntryMutation = gql`
  mutation adminCancelEntry($payload: CancelEntriesInput!) {
    cancelEntries(payload: $payload) {
      registered_entries_id
      registration_id
      tour_id
      event_id
      event_date
      event_name
      status
      canceled_at
      refunded_at
      refund_id
      refund_status
    }
  }
`;

export const adminCancelTournamentMutation = gql`
  mutation adminCancelTournament(
    $notifyAll: Boolean!
    $refund: Boolean!
    $tour_id: Int!
    $reason: String!
    $show: Boolean!
  ) {
    cancelTournament(
      notifyAll: $notifyAll
      refund: $refund
      tour_id: $tour_id
      reason: $reason
      show: $show
    ) {
      status
    }
  }
`;

export const memberContactSponsorMutation = gql`
  mutation memberContactSponsor(
    $contact_reason: String!
    $member_number: Int!
    $message: String!
    $tour_id: Int!
  ) {
    contactSponsors(
      contact_reason: $contact_reason
      member_number: $member_number
      message: $message
      tour_id: $tour_id
    ) {
      ok
    }
  }
`;

export const adminUpdateRegistrationEntryQuery = gql`
  mutation adminUpdateRegistrationEntry($payload: [RegisteredEntryInput!]!) {
    updateRegistrationEntry(payload: $payload) {
      active
    }
  }
`;

export const adminUpdateEventSeatQuery = gql`
  mutation adminUpdateEventSeat($eventId: Int!, $sessionNumber: Int!, $payload: SchEventInput!) {
    updateEvent(event_id: $eventId, session_number: $sessionNumber, payload: $payload) {
      active
    }
  }
`;

export const saveTournamentMutation = gql`
  mutation saveTournament($member_number: Int!, $tournaments: [SavedTournamentPayload!]!) {
    saveTournament(member_number: $member_number, tournaments: $tournaments) {
      success: ok
    }
  }
`;

export const unsaveTournamentMutation = gql`
  mutation unsaveTournament($member_number: Int!, $tournaments: [Int!]!) {
    unsaveTournament(member_number: $member_number, tournaments: $tournaments) {
      success: ok
    }
  }
`;

export const saveSuggestedMembersMutation = gql`
  mutation saveSuggestedMember($member_number: Int!, $members: [Int!]!) {
    saveSuggestedMember(member_number: $member_number, members: $members) {
      ok
    }
  }
`;

export const updateRegisteredEntriesByMemberMutation = gql`
  mutation updateRegisteredEntryByMember($payload: UpdateRegistrationEntryInput!) {
    updateRegisteredEntryByMember(payload: $payload) {
      created_at
    }
  }
`;

export const saveGoingPerRegistrationFormMutation = gql`
  mutation saveGoingPreRegistrationForm($memberId: Int!, $tournaments: [SavedTournamentPayload!]!) {
    saveTournament(member_number: $memberId, tournaments: $tournaments) {
      ok
    }
  }
`;

export const createVolunteersMutation = gql`
  mutation createVolunteers($payload: [VolunteerInput!]!) {
    createVolunteers(payload: $payload) {
      active
      freeGamesCount: free_games_count
      memberNumber: member_number
      redeemedGamesCount: redeemed_games_count
      reminingGamesCount: remaining_games_count
      member {
        firstName: first_name
        lastName: last_name
        totalMP: total_masterpoints
        member_number
        email
      }
    }
  }
`;

export const updateVolunteersMutation = gql`
  mutation updateVolunteers($payload: [VolunteerUpdateInput!]!) {
    updateVolunteers(payload: $payload) {
      active
      freeGamesCount: free_games_count
      memberNumber: member_number
      redeemedGamesCount: redeemed_games_count
      reminingGamesCount: remaining_games_count
      member {
        firstName: first_name
        lastName: last_name
        totalMP: total_masterpoints
        memberNumber: member_number
      }
    }
  }
`;

export const removeVolunteersByIdMutation = gql`
  mutation removeVoluntersById($volunteers: [Int!]!) {
    removeVoluntersById(volunteers: $volunteers)
  }
`;

export const finalizeSeatingAssignmentMutation = gql`
  mutation finalizeSeatingAssignment($event_id: Int!, $session_number: Int!) {
    finalizeSeatingAssignment(event_id: $event_id, session_number: $session_number) {
      finalized
    }
  }
`;

export const updateTournamentDiscountMutation = gql`
  mutation updateTournamentDiscount(
    $payload: TournamentDiscountInput!
    $tour_id: Int!
    $useAdminView: Boolean
  ) {
    updateTournamentDiscount(payload: $payload, tour_id: $tour_id, useAdminView: $useAdminView) {
      junior
      youth
    }
  }
`;

export const manageEventPricingMutation = gql`
  mutation manageEventPricing($payload: [EventPricingPayload], $tour_id: Int!) {
    manageEventPricing(payload: $payload, tour_id: $tour_id) {
      active
    }
  }
`;

export const chargeEntriesMutation = gql`
  mutation chargeEntries($payments: [String!]!, $eventId: Int, $type: EPreAuthPaymentType) {
    chargeOrCancelPreAuthorizedPayments(payments: $payments, eventId: $eventId, type: $type) {
      failed
      success {
        active
      }
    }
  }
`;
