/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DateType {
  canceled = "canceled",
  past = "past",
  saved = "saved",
  upcoming = "upcoming",
}

export enum EAdminStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum EPaginationSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum EPaymentStatus {
  APPROVED = "APPROVED",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  REFUNDED = "REFUNDED",
}

export enum EPreAuthPaymentType {
  CANCEL = "CANCEL",
  CHARGE = "CHARGE",
}

export enum ERefundStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum ERegisteredParticipant {
  created_at = "created_at",
  email = "email",
  event_id = "event_id",
  first_name = "first_name",
  last_name = "last_name",
  member_number = "member_number",
  member_type = "member_type",
  registered_entries_id = "registered_entries_id",
  registered_participant_id = "registered_participant_id",
  total_masterpoints = "total_masterpoints",
  tour_id = "tour_id",
  updated_at = "updated_at",
}

export enum ERegistrationStatsMembers {
  id = "id",
  member_name = "member_name",
  member_number = "member_number",
  registered_entries = "registered_entries",
  total_masterpoints = "total_masterpoints",
  total_paid = "total_paid",
}

export enum ERegistrationStatus {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  REFUNDED = "REFUNDED",
}

export enum ESavedTournamentStatus {
  GOING = "GOING",
  INTERESTED = "INTERESTED",
}

export enum ESavedTournamentsDateRange {
  ALL = "ALL",
  PAST = "PAST",
  UPCOMING = "UPCOMING",
}

export enum ESeatingMethod {
  ENTRY = "ENTRY",
  GAME_TABLE = "GAME_TABLE",
  TABLE_SEED = "TABLE_SEED",
  TEAM = "TEAM",
}

export enum ESortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ETourEndDateStatus {
  PAST = "PAST",
  UPCOMING = "UPCOMING",
}

export enum SeatingMethod {
  ENTRY = "ENTRY",
  GAME_TABLE = "GAME_TABLE",
  TABLE_SEED = "TABLE_SEED",
  TEAM = "TEAM",
}

export enum StatusFilter {
  ARCHIVED = "ARCHIVED",
  NULL = "NULL",
}

export enum TabsFilter {
  ALL = "ALL",
  PAST = "PAST",
  UPCOMING = "UPCOMING",
}

export enum TourEndDateStatus {
  PAST = "PAST",
  UPCOMING = "UPCOMING",
}

export interface AutocompleteFilters {
  tabs?: TabsFilter | null;
}

export interface CancelEntriesInput {
  isCanceledByAdmin?: boolean | null;
  member_number?: number | null;
  payment_id?: string | null;
  reason?: string | null;
  refund: boolean;
  registered_entries_ids?: (number | null)[] | null;
  sendEmailToPlayers: boolean;
}
export interface CancelNonQualifiedEntriesInput {
  eventId: number;
  type?: EPreAuthPaymentType;
}


export interface CurrentRegisteredMemberInput {
  fieldName?: ERegisteredParticipant[] | null;
  fieldValue?: string[] | null;
  member_number?: number | null;
}

export interface DateRange {
  date_from: string;
  date_to: string;
}

export interface EntryInput {
  additional_requests?: string | null;
  assigned_seats?: boolean | null;
  double_ew?: boolean | null;
  seed_request?: boolean | null;
  canceled_at?: string | null;
  electric_plug?: boolean | null;
  event_date?: string | null;
  event_id?: string | null;
  event_name?: string | null;
  event_type?: string | null;
  multi_sessional_ko?: boolean | null;
  number_of_sessions?: number | null;
  participants?: (RegisteredParticipantInput | null)[] | null;
  per_person_price?: number | null;
  refunded_at?: string | null;
  session_number?: number | null;
  session_time_ampm?: string | null;
  status?: ERegistrationStatus | null;
  total_price?: number | null;
  tour_id?: string | null;
}

export interface EventEntryInput {
  event_id: number;
  member_number: number[];
}

export interface EventPricingPayload {
  allow_5_and_6?: boolean | null;
  charge_5_and_6?: boolean | null;
  event_id: string;
  sessions_to_charge?: number | null;
  warning_message?: string | null;
}

export interface InterestedMembersFilter {
  status?: (ESavedTournamentStatus | null)[] | null;
}

export interface InterestedMembersPagination {
  direction?: EPaginationSortDirection | null;
  limit?: number | null;
  offset?: number | null;
  search?: string | null;
  sortBy?: string | null;
}

export interface MyRegistartionFilters {
  status?: DateType | null;
}

export interface Pagination {
  direction?: string | null;
  endDate?: string | null;
  limit?: number | null;
  offset?: number | null;
  search?: string | null;
  sortBy?: string | null;
  startDate?: string | null;
}

export interface PreRegistrationPaymentInput {
  cardholder_email: string;
  cardholder_name: string;
  events?: (number | null)[] | null;
  member_number: number;
  multiSessionTokens?: (string | null)[] | null;
  sourceId: string;
  total_price: number;
  tour_id: number;
}

export interface RegisteredEntryInput {
  entry?: string | null;
  entry_id: number;
  game_table?: string | null;
  table_seed?: string | null;
  team?: string | null;
}

export interface RegisteredParticipantInput {
  email?: string | null;
  event_id?: number | null;
  first_name?: string | null;
  last_name?: string | null;
  member_number?: number | null;
  member_type?: string | null;
  registered_entries_id?: number | null;
  total_masterpoints?: number | null;
  tour_id?: number | null;
}

export interface RegistrationInput {
  canceled_at?: string | null;
  covid_19_accepted_date?: string | null;
  covid_19_vaccine?: boolean | null;
  events?: (EntryInput | null)[] | null;
  host_hotel?: boolean | null;
  host_hotel_check_in_date?: string | null;
  host_hotel_check_out_date?: string | null;
  host_hotel_rooms?: number | null;
  legal_agreement?: boolean | null;
  refunded_at?: string | null;
  registering_member_email?: string | null;
  registering_member_first_name?: string | null;
  registering_member_last_name?: string | null;
  registering_member_number?: string | null;
  status?: ERegistrationStatus | null;
  total_price?: number | null;
  tour_address?: string | null;
  tour_date?: string | null;
  tour_id?: string | null;
  tour_name?: string | null;
  tour_phone?: string | null;
  tour_site?: string | null;
  tour_type?: string | null;
}

export interface RegistrationPagination {
  event_id: string;
  limit?: number | null;
  offset?: number | null;
  session_number?: number | null;
  status?: ERegistrationStatus | null;
}

export interface RegistrationStatsPagination {
  direction?: ESortDirection | null;
  limit?: number | null;
  offset?: number | null;
  sortBy?: ERegistrationStatsMembers | null;
}

export interface SavedTournamentPayload {
  host_hotel: boolean;
  host_hotel_check_in_date?: any | null;
  host_hotel_check_out_date?: any | null;
  status: ESavedTournamentStatus;
  tour_id: number;
}

export interface SavedTournamentsByMemberFilter {
  dateRange?: ESavedTournamentsDateRange | null;
  status?: ESavedTournamentStatus | null;
  tourId?: (number | null)[] | null;
}

export interface SchEventInput {
  seating_method?: SeatingMethod | null;
}

export interface SchTourneyInput {
  active?: boolean | null;
  status?: StatusFilter | null;
}

export interface TotalEnteryPriceInput {
  allow_5_and_6?: boolean | null;
  base_fee?: number | null;
  charge_5_and_6?: boolean | null;
  event_id?: number | null;
  game_type_name?: string | null;
  sessions_to_charge?: number | null;
  total_price?: number | null;
}

export interface TournamentDiscountInput {
  junior?: number | null;
  youth?: number | null;
}

export interface UpdateRegistrationEntryInput {
  added_member_ids?: (number | null)[] | null;
  additional_requests?: string | null;
  assigned_seats?: boolean | null;
  double_ew?: boolean | null;
  seed_request?: boolean | null;
  current_membed_id: number;
  electric_plug?: boolean | null;
  registered_entries_id: number;
  removed_member_ids?: (number | null)[] | null;
}

export interface VEventFilters {
  active?: boolean | null;
  event_id?: (number | null)[] | null;
  game_type_name?: (string | null)[] | null;
  mp_limit?: (string | null)[] | null;
  session_date?: (string | null)[] | null;
  session_time?: (string | null)[] | null;
  session_time_ampm?: (string | null)[] | null;
  strata_code?: (string | null)[] | null;
  strata_name?: (string | null)[] | null;
  tour_id?: (number | null)[] | null;
}

export interface VTournamentFilters {
  active?: boolean | null;
  date_range?: DateRange | null;
  month?: number | null;
  not_status?: StatusFilter | null;
  sanction?: (number | null)[] | null;
  site_city?: (string | null)[] | null;
  site_state_prov?: (string | null)[] | null;
  status?: (string | null)[] | null;
  tabs?: TabsFilter | null;
  tour_category_name?: (string | null)[] | null;
  tour_district?: (string | null)[] | null;
  tour_id?: (number | null)[] | null;
  tour_type_name?: (string | null)[] | null;
  tour_unit?: (string | null)[] | null;
  year?: number | null;
}

export interface VolunteerFilter {
  active?: boolean | null;
  member_number?: (number | null)[] | null;
  tour_id?: (number | null)[] | null;
}

export interface DiscountOrCouponFilter {
  active?: boolean | null;
  member_number?: (number | null)[] | null;
  tour_id?: (number | null)[] | null;
}

export interface VolunteerInput {
  active?: boolean | null;
  created_at?: any | null;
  free_games_count?: number | null;
  member_comments?: string | null;
  member_number: number;
  redeemed_games_count?: number | null;
  tour_id: number;
  tournament_role: string;
  updated_at?: any | null;
}

export interface VolunteerUpdateInput {
  active?: boolean | null;
  created_at?: any | null;
  free_games_count?: number | null;
  member_comments?: string | null;
  member_number?: number | null;
  redeemed_games_count?: number | null;
  tour_id?: number | null;
  tournament_free_play_id: number;
  tournament_role?: string | null;
  updated_at?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
