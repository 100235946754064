/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
const initialState = () => null;

export const refetechSlice = createSlice({
  name: 'refetech',
  initialState,
  reducers: {
    clearRefetchQury: (state, action: PayloadAction<any>) => action.payload,
    updateRefechQuery: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
  },
});

export const { updateRefechQuery, clearRefetchQury } = refetechSlice.actions;
export default refetechSlice.reducer;
