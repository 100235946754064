/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import React, {
  forwardRef,
  MouseEvent,
  PropsWithRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Icon } from 'uikit/Icon';
import useOutsideClickHandler from 'utils/hooks/useOutsideClickHandler';
import { scrollToAnchor } from 'utils/scrollToAnchor';

import {
  DropdownItem,
  IconWrapper,
  SelectedField,
  StyledDropdown,
  StyledSelect,
  StyledWrapper,
} from './styles';
import { ListItem, SelectRefProps } from './types';

type SelectProps = {
  selectedItem: string;
  setValue: (value?: unknown) => void;
  items: ListItem[];
  defaultValue?: ListItem;
  readOnly?: boolean;
  name?: string;
  placeholder?: string;
  width?: number;
  showCross?: boolean;
  value?: string;
  ref?: null;
};

const Select: React.FC<SelectProps & PropsWithRef<any>> = forwardRef<SelectRefProps, SelectProps>(
  (
    {
      selectedItem,
      readOnly = true,
      showCross = true,
      width,
      items: list,
      setValue,
      placeholder,
      defaultValue,
      ...props
    },
    ref = null
  ) => {
    const { name = 'test' } = props;
    const [show, setShow] = useState(false);
    const [items, setItems] = useState<ListItem[]>(list);

    const [selected, setSelected] = useState('');
    const wrapperRef = useRef(null);

    useOutsideClickHandler(wrapperRef, () => setShow(false));

    const handleClick = () => {
      setShow(!show);
    };

    useEffect(() => {
      if (defaultValue) {
        setSelected(defaultValue[selectedItem]);
        setValue(defaultValue[selectedItem]);
      }
    }, []);

    useEffect(() => {
      if (isEmpty(items)) {
        setItems(list);
      }
    }, [list]);
    const { setFieldValue } = useFormikContext();

    useImperativeHandle(ref, () => ({
      clearSelect() {
        // setFieldValue(name, '');
        setTimeout(() => (props.value = ''));
      },
    }));

    const handleSelect = (item) => {
      setSelected(item[selectedItem]);
      setValue(item[selectedItem]);
      scrollToAnchor(`#${item.id}`);
      setShow(false);
    };

    const handleCrossButton = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setValue(null);
        setSelected('');
        setFieldValue(name, '');
      },
      [setValue, setFieldValue, name]
    );

    const arrow = useMemo(() => {
      return show ? 'arrowBottomActive' : 'arrowBottom';
    }, [show]);

    const handleSelectedChange = ({ target: { value } }) => {
      if (!value) {
        setItems(list);
      } else {
        setItems(
          list.filter((item) => {
            const v: string = (item[selectedItem] as string).toUpperCase();
            return v.includes(value.toUpperCase());
          })
        );
      }
    };

    return (
      <StyledWrapper ref={wrapperRef}>
        <StyledSelect onClick={handleClick} open={show}>
          {readOnly ? (
            <SelectedField width={width} readOnly name={name} placeholder={placeholder} />
          ) : (
            <SelectedField
              width={width}
              autoComplete='off'
              name={name}
              placeholder={placeholder}
              onInput={handleSelectedChange}
            />
          )}
          <IconWrapper>
            {selected && showCross ? (
              <div onClick={(e) => handleCrossButton(e)}>
                <Icon icon='cross' size={13} alt='cross' />
              </div>
            ) : (
              <Icon icon={arrow} size={13} alt='select' />
            )}
          </IconWrapper>
        </StyledSelect>

        <StyledDropdown show={show}>
          {!isEmpty(items) ? (
            items.map((item) => (
              <DropdownItem
                key={item.id}
                isActive={selected === item[selectedItem]}
                onClick={() => handleSelect(item)}
              >
                <div id={item.id}>{item[selectedItem]}</div>
              </DropdownItem>
            ))
          ) : (
            <DropdownItem>No options</DropdownItem>
          )}
        </StyledDropdown>
      </StyledWrapper>
    );
  }
);
export default Select;
