import moment from 'moment';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  start: yup.string(),
  end: yup
    .string()
    .test('end', 'Check in and check out dates must be different', function (end) {
      const start = this.parent.start;

      if (end !== start) {
        return true;
      }

      return false;
    })
    .test('end', "Check in can't be more check out", function (value) {
      const start = moment(value).utc().diff(this.parent.start);

      if (start > 0) {
        return true;
      }

      return false;
    }),
});
