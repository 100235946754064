export const TIME_FORMAT = {
  SHORT_NUMERIC: 'MM/DD/YYYY',
  TIME_ZONE: 'America/Los_Angeles',
};

export const SavedTourDateRangeProps = {
  UPCOMING: 'UPCOMING',
  PAST: 'PAST',
  ALL: 'ALL',
};

export const SkipableOperationNamesOnError = ['createRegistration'];
