/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TNotEligibleMember } from './types';

const initialState: TNotEligibleMember[] = [];

export const notEligibleMembers = createSlice({
  name: 'notEligibleMembers',
  initialState,
  reducers: {
    clearNotEligibleMembers: () => initialState,
    addNotEligibleMember: (state, action: PayloadAction<TNotEligibleMember[]>) => {
      return [...new Set([...state, ...action.payload])];
    },
    removeNotEligibleMember: (state, action: PayloadAction<TNotEligibleMember>) => {
      return state.filter((i) => i !== action.payload);
    },
  },
});

export const { clearNotEligibleMembers, addNotEligibleMember, removeNotEligibleMember } =
  notEligibleMembers.actions;
export { initialState as notEligibleMembersInitialState };
export default notEligibleMembers.reducer;
