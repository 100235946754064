import type { RootState } from '../index';
import { TModalNames, TSingleState } from './types';

export const selectModals = (store: RootState) => {
  return store.modals;
};

export const selectModalByName =
  <TName extends TModalNames>(name: TName) =>
  (store: RootState) => {
    return store.modals[name] as TSingleState<TName>;
  };
