export const DEFAULT_TOUR_START_DATE_FORMAT = 'MMM DD, YYYY';

export const CATEGORY_COLORS = {
  Sectional: '#CDC1DD',
  'GNT/NAP': '#C6E7EB',
  NABC: '#FCC5BA',
  Regional: '#FFE59D',
  Other: '#E0EBC9',
};

export const CATEGORY_TITLE_COLORS = {
  Sectional: '#7D4B8A',
  'GNT/NAP': '#008290',
  NABC: '#E0522E',
  Regional: '#E08B0E',
  Other: '#6E8A34',
};
