import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { pxToRem } from 'utils/helpers';

export const Wrapper = styled.div`
  width: 100%;
`;

export const ErrorMessage = styled.span`
  font-size: ${pxToRem(12)};
  letter-spacing: ${pxToRem(0.5)};
  color: ${COLORS.BlueDark};
  font-weight: 400;
  margin-left: ${pxToRem(5)};
`;
