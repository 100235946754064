import { createTheme, withStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem } from '@material-ui/core';

export const AcblTheme = createTheme({
  palette: {
    primary: {
      main: '#00529b',
      dark: '#1c2455',
    },
    secondary: {
      main: 'rgb(242, 101, 65)',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
});

export const OpenSans = createTheme({
  typography: {
    fontFamily: ['Open Sans'].join(','),
  },
});

export const ACBLMenu = withStyles({
  root: {
    color: '#00529b',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: '#fff',
  },
  // @ts-ignore
})(Menu);

export const ACBLMenuItem = withStyles({
  root: {
    color: '#00529b',
    paddingLeft: '30px',
    minHeight: '40px',
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
})(MenuItem);

export const ACBLButton = withStyles({
  containedPrimary: {
    '&:hover': {
      backgroundColor: '#00509b',
    },
  },
  label: {
    textTransform: 'capitalize',
  },
})(Button);
