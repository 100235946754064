/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';

import { IconWrapper } from './Icon.styles';

import { ICON_NAMES } from './icon.names';

import { ICON_COMPONENTS } from './icon.components';

export type TIconString = keyof typeof ICON_NAMES | string;

type IconType = {
  icon: TIconString;
  size?: number;
  alt: string;
  hover?: string;
  onClick?: (e: Event) => void;
  className?: string;
  isComponent?: boolean;
};

const Icon: React.FC<IconType> = ({
  icon,
  size = 14,
  alt = '',
  onClick,
  className = '',
  isComponent = false,
}) => {
  const Component = ICON_COMPONENTS[icon];
  const src = ICON_NAMES[icon];
  return (
    <>
      {isComponent && Component ? (
        <Component width={size} height={size} alt={alt} onClick={onClick} className={className} />
      ) : (
        <IconWrapper size={size} src={src} alt={alt} onClick={onClick} className={className} />
      )}
    </>
  );
};

export default Icon;
