import styled from 'styled-components';
import logo from 'assets/img/ACBL_Logo_MicroSeal2_White.png';
import { COLORS } from '../styles/colors';
import DefaultFooter from '../components/Footers/DefaultFooter';
import { pxToRem } from '../utils/helpers';
import { ContentContainer } from '../components/Containers/ContentContainer.styles';
import { useAppSelector } from '../store/hooks';
import { selectIsAdminLoggedIn } from '../store/profile/selectors';
import { Redirect } from 'react-router';
import { ADMIN_SIGN_IN } from '../routes/routes.map';

const AdminLayout: React.FC = ({ children }) => {
  const isAdminLogIn = useAppSelector(selectIsAdminLoggedIn);
  if (!isAdminLogIn) return <Redirect to={ADMIN_SIGN_IN} />;

  return (
    <>
      <Header>
        <Content>
          <img
            src={logo}
            alt='logo'
            width='50px'
            onClick={() => window.open('https://www.acbl.org/')}
            style={{ cursor: 'pointer' }}
          />
        </Content>
      </Header>
      <ChildContainer>{children}</ChildContainer>
      <DefaultFooter />
    </>
  );
};

const Header = styled.div`
  background: ${COLORS.BlueDarkActive};
`;

const Content = styled(ContentContainer)`
  min-height: ${pxToRem(80)};
  img {
    padding: ${pxToRem(20)} 0;
  }
`;

const ChildContainer = styled.div`
  min-height: 100vh;
  height: auto;
`;

export default AdminLayout;
