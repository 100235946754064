// React Libraries
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// Hooks and Services
import AppLayout from './Layout/Layout';

import { useMemberProfile } from './helpers/MemberData';
import GlobalFonts from './assets/fonts';
import { GlobalStyle } from './global.styles';
import { Loader } from 'uikit/Loader';
import store, { persistor } from 'store';
import Routes from './routes/Routes';
import { withApollo } from 'services/WithApollo';
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './helpers/ErrorBoundary';

const App = withApollo(() => {
  const { loading } = useMemberProfile();

  if (loading) return <Loader loading />;

  return (
    <>
      <Routes />
    </>
  );
});

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
        <GlobalFonts />
        <GlobalStyle />
        <AppLayout>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </AppLayout>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
