import { VIEWPORTS } from 'styles/responsive.styles';
import { pxToRem } from 'utils/helpers';
import { COLORS } from 'styles/colors';
import styled, { keyframes } from 'styled-components';

const INDEX = 999;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-15px)
  }
  100% {
    opacity: 1;
  }
`;

export const PopupWrapper = styled.div``;

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: ${INDEX};
`;
export const PopupContainer = styled.div<{ width: number; overflow: string }>`
  border-radius: ${pxToRem(10)};
  animation: ${fadeIn} 500ms;
  box-sizing: border-box;
  z-index: ${INDEX + 1};
  position: fixed;
  top: ${pxToRem(110)};
  width: 90%;
  max-width: ${({ width }) => pxToRem(width)};
  left: 0;
  right: 0;
  margin: 0 auto;
  background: ${COLORS.White};
  box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.1);
  overflow: ${({ overflow }) => overflow};
  max-height: calc(100vh - ${pxToRem(100)} - 5%);

  @media ${VIEWPORTS.Mobile} {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 0;
    bottom: 0;
    max-height: 100vh;
  }
`;

export const PopupClose = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const PopupHeader = styled.header`
  border-bottom: ${pxToRem(1)} solid ${COLORS.BorderGrey};
  height: ${pxToRem(88)};
  padding: 0 ${pxToRem(48)};
  display: flex;
  align-items: center;

  @media ${VIEWPORTS.Tablet} {
    padding: 0 ${pxToRem(24)};
  }

  @media ${VIEWPORTS.TabletLandscape} {
    padding: 0 ${pxToRem(24)};
  }

  @media ${VIEWPORTS.Mobile} {
    padding: 0 ${pxToRem(24)};
  }

  @media ${VIEWPORTS.MobileLandscape} {
    padding: 0 ${pxToRem(20)};
  }
`;

/**
 * I commented this style please see REG-800
 */
export const PopupBody = styled.div`
  /* padding: 0 ${pxToRem(20)}; */
  .price {
    font-style: normal;
    font-style: normal;
    font-weight: 800;
    font-size: ${pxToRem(20)};
    line-height: ${pxToRem(24)};
    color: ${COLORS.BlueDark};
  }

  @media ${VIEWPORTS.Mobile} {
    padding: 0 ${pxToRem(24)} 0;
  }
`;

export const PopupFooter = styled.div`
  min-height: ${pxToRem(111)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${pxToRem(48)};
  border-top: ${pxToRem(1)} solid ${COLORS.BorderGrey};

  @media ${VIEWPORTS.Mobile} {
    padding: 0;
  }
`;
