import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TModalNames } from './types';
import { TModalData, TStateType } from './types';

const initialState: TStateType = {};

export type TPayload<TName extends TModalNames> = {
  name: TName;
  data?: TModalData<TName>;
};

type TOpenAction = PayloadAction<TPayload<TModalNames>>;
type TCloseAction = PayloadAction<{ name: TModalNames }>;

export const profileSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: (state, { payload }: TOpenAction) => {
      state[payload.name] = {
        data: payload.data || state[payload.name].data,
        show: true,
      };
      return state;
    },
    hideModal: (state, { payload }: TCloseAction) => {
      state[payload.name].show = false;
      return state;
    },
  },
});

export const { showModal, hideModal } = profileSlice.actions;

export const showModalActionCreator = <TName extends TModalNames>(data: {
  name: TName;
  data?: TModalData<TName>;
}) => {
  return showModal(data as TModalData<TModalNames>);
};

export { initialState as modalsInitialState };
export default profileSlice.reducer;
