/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IRegistrationProps,
  IEnteryProps,
  IEnteryInput,
  IRegistrationInput,
  IUpdateEvent,
  IRemoveRegistration,
} from './types';
import unionBy from 'lodash/unionBy';

const initialState: IRegistrationProps = {
  registering_member_number: 0,
  registering_member_email: '',
  registering_member_last_name: '',
  registering_member_first_name: '',
  tour_id: 0,
  tour_name: '',
  tour_phone: '',
  tour_type: '',
  tour_date: '',
  tour_address: '',
  covid_19_vaccine: false,
  covid_19_accepted_date: new Date().toUTCString(),
  legal_agreement: false,
  host_hotel: false,
  host_hotel_rooms: 0,
  host_hotel_check_in_date: new Date().toUTCString(),
  host_hotel_check_out_date: new Date().toUTCString(),
  total_price: 0,
  events: [],
  numberOfMultiSessionEvents: 0,
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    clearRegistration: () => initialState,
    addEvent: (state, action: PayloadAction<IEnteryProps>) => {
      let events = state.events;
      if (action.payload.tour_id && action.payload.event_id) {
        events = unionBy(state.events, [action.payload], 'event_id');
      }
      const total_price = calculateTotalAmount(events);
      return {
        ...state,
        events,
        total_price,
      };
    },
    removeEvent: (state, action: PayloadAction<IRemoveRegistration>) => {
      let events = state.events;
      if (action.payload.event_id) {
        events = removeEventById(state.events, action.payload.event_id);
      }
      const total_price = calculateTotalAmount(events);
      return { ...state, events, total_price };
    },
    updateEvent: (state, { payload }: { payload: IUpdateEvent }) => {
      const { data, event_id = 0 } = payload;
      const events = updateEventById(state.events, data, event_id);
      return { ...state, events };
    },
    updateRegistration: (state, action: PayloadAction<IRegistrationInput>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

function calculateTotalAmount(events: IEnteryInput[]) {
  return events.reduce((total, item) => (total += item.total_price), 0).toFixed(2);
}

function updateEventById(events: IEnteryProps[], payload: IEnteryInput, event_id = 0) {
  return events.map((item) => {
    if (item.event_id === event_id) {
      return { ...item, ...payload };
    }
    return item;
  });
}

function removeEventById(events: IEnteryProps[], event_id = 0) {
  return events.filter((item) => {
    if (item.event_id !== event_id) {
      return item;
    }
    return false;
  });
}

export const { addEvent, updateEvent, updateRegistration, removeEvent, clearRegistration } =
  registrationSlice.actions;
export { initialState as registrationInitialState };
export default registrationSlice.reducer;
