import styled from 'styled-components';
import { pxToRem } from '../../utils/helpers';

export interface ContentContainerProps {
  marginTop?: number;
  marginBottom?: number;
  marginSides?: number;
  maxWidth?: string;
}
export const ContentContainer = styled.div<ContentContainerProps>`
  margin-top: ${({ marginTop }) => pxToRem(marginTop as number)};
  margin-bottom: ${({ marginBottom }) => pxToRem(marginBottom as number)};
  // don't remove this padding as it's breaking admin side ui
  padding: 0 ${({ marginSides = 40 }) => pxToRem(marginSides as number)};
  max-width: ${({ maxWidth = 'initial' }) => maxWidth};
`;
