import React from 'react';
import { FieldWrapperProps } from './FieldWrapper.types';
import { useFieldWrapper } from './hooks/useFieldWrapper';
import { Wrapper } from './FieldWrapper.styles';

const FieldWrapper: React.FC<FieldWrapperProps> = ({
  id: userDefinedId,
  name,
  value,
  children,
  messageId,
  required = false,
}) => {
  const { id, hasError, field, ...attrs } = useFieldWrapper({
    name,
    messageId,
    required,
    value,
    id: userDefinedId,
  });
  return (
    <Wrapper>
      {children({
        ...attrs,
        ...field,
        hasError,
        id,
      })}
    </Wrapper>
  );
};

export default FieldWrapper;
