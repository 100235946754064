import React, { useCallback, useState } from 'react';
import { Wrapper, Message } from './ErrorNotifier.styles';

type Props = {
  children: (v: unknown) => React.ReactElement;
};

export const ErrorNotifier: React.FC<Props> = ({ children }) => {
  const [doShowError, setDoShowError] = useState(false);
  const [message, setMessage] = useState('');
  const showError = useCallback((message = '') => {
    setDoShowError(true);
    setMessage((prev) => `${prev}${message}\n\n`);
  }, []);
  return doShowError ? (
    <Wrapper>
      <Message>{message}</Message>
    </Wrapper>
  ) : (
    children(showError)
  );
};
