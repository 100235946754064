import { Fragment, Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Switch, useHistory, withRouter } from 'react-router-dom';
import * as pagesRoutes from './routes.map';

import AdminLayout from 'Layout/AdminLayout';
import Typography from '@material-ui/core/Typography';
import { Loader } from 'uikit/Loader';
import LandingPageHeader from 'components/Headers/LandingPageHeader';
import MainLayout from 'Layout/MainLayout';
import DefaultFooter from 'components/Footers/DefaultFooter';
import { Modals } from '../components/Modals';
import styled from 'styled-components';

const PaymentStep = lazy(() => import('components/PreRegister/steps/PaymentStep/Payments'));
const ManageTournaments = lazy(() => import('views/ManageTournaments/ManageTournaments'));
const ManageTournament = lazy(() => import('views/ManageTournament/ManageTournament'));
const ManageTournamentGame = lazy(() => import('views/ManageTournamentGame/ManageTournamentGame'));
const MyTournaments = lazy(() => import('views/MyTournaments/MyTournaments'));
const MyTournamentDetails = lazy(() => import('views/MyTournamentDetails/MyTournamentDetails'));
const TournamentPage = lazy(() => import('views/TournamentPage/TournamentPage'));
const TournamentDetail = lazy(() => import('views/TournamentDetail'));
const PageNotFound = lazy(() => import('views/PageNotFound/TournamentPage/PageNotFound'));
const EmptyPage = lazy(() => import('uikit/EmptyPage'));
const AdminAuth = lazy(() => import('views/Auth/AdminAuth'));
const ResetAdminPassword = lazy(() => import('views/Auth/AdminPasswordReset'));
const TournamentPricing = lazy(
  () => import('views/ManageTournamentPricing/ManageTournamentPricing')
);

const TournamentsSearchPage = lazy(() => import('views/ManageTournamentsSearch'));
const ClientTournamentsSearchPage = lazy(() => import('views/ClientTournamentsSearch'));
// const Authentication = lazy(() => import('helpers/Authentication'));

interface IRoute {
  path: string;
  component?: ReturnType<typeof lazy>;
  exact?: boolean;
  hasHeader?: boolean;
  hasFooter?: boolean;
  Layout?: React.FC;
  children?: IRoute[];
}

const routes: IRoute[] = [
  {
    exact: true,
    path: pagesRoutes.PAYMENTS_PAGE,
    component: PaymentStep,
  },
  {
    exact: true,
    path: pagesRoutes.MY_TOURNAMENTS_PAGE,
    component: MyTournaments,
    hasHeader: true,
    hasFooter: true,
  },
  {
    exact: true,
    path: pagesRoutes.MY_TOURNAMENTS_DETAILS_PAGE,
    component: MyTournamentDetails,
    hasHeader: true,
    hasFooter: true,
  },
  {
    exact: true,
    path: '/payment',
    component: PaymentStep,
    hasHeader: true,
    hasFooter: true,
  },
  // {
  //   exact: true,
  //   path: '/login',
  //   component: Authentication,
  //   hasHeader: true,
  //   hasFooter: true,
  // },
  {
    path: pagesRoutes.HOME_PAGE,
    Layout: MainLayout,
    children: [
      {
        exact: true,
        path: pagesRoutes.HOME_PAGE,
        component: TournamentPage,
      },
      {
        exact: true,
        path: pagesRoutes.TOURNAMENT_DETAIL_PAGE,
        component: TournamentDetail,
      },
      {
        path: pagesRoutes.CLIENT_TOURNAMENTS_SEARCH_PAGE,
        exact: true,
        component: ClientTournamentsSearchPage,
      },
    ],
  },
  {
    exact: true,
    path: pagesRoutes.ADMIN_SIGN_IN,
    component: AdminAuth,
    hasHeader: true,
    hasFooter: true,
  },
  {
    exact: true,
    path: pagesRoutes.ADMIN_PASSWORD_RESET,
    component: ResetAdminPassword,
    hasHeader: true,
    hasFooter: true,
  },
  {
    path: pagesRoutes.ADMIN_PAGE,
    Layout: AdminLayout,
    children: [
      {
        path: pagesRoutes.TOURNAMENTS_MANAGE_PAGE,
        component: ManageTournaments,
        exact: true,
      },
      {
        path: pagesRoutes.TOURNAMENT_MANAGE_PAGE,
        component: ManageTournament,
      },
      {
        path: pagesRoutes.TOURNAMENT_GAME_PAGE,
        component: ManageTournamentGame,
      },
      {
        path: pagesRoutes.TOURNAMENT_PRICING_PAGE,
        component: TournamentPricing,
      },
      {
        path: pagesRoutes.TOURNAMENTS_SEARCH_PAGE,
        component: TournamentsSearchPage,
      },
    ],
  },

  {
    exact: false,
    path: '*',
    component: PageNotFound,
  },
];

const WithScrollToTop = withRouter(ScrollToTop);

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const ContentWrapper = styled.div`
  flex: 1;
`;

const RouteDrawer: React.FC<IRoute> = ({
  component,
  Layout,
  exact,
  path,
  children,
  hasHeader,
  hasFooter,
}) => {
  const Wrapper = Layout || Fragment;

  if (children) {
    return (
      <Route path={path} exact={!!exact}>
        <Wrapper>
          <Suspense
            fallback={
              <Typography variant='h5'>
                <Loader loading />
              </Typography>
            }
          >
            {hasHeader && <LandingPageHeader />}
            <Switch>
              {children.map((child) => {
                return <RouteDrawer {...child} key={child.path} />;
              })}
              <RouteDrawer path='*' exact={false} component={EmptyPage} />
            </Switch>
          </Suspense>
        </Wrapper>
      </Route>
    );
  }

  return (
    <Suspense
      fallback={
        <Typography variant='h5'>
          <Loader loading />
        </Typography>
      }
    >
      <MainWrapper>
        {hasHeader && <LandingPageHeader />}
        <ContentWrapper>
          <Route path={path} exact={exact} component={component} />
        </ContentWrapper>
        <div>{hasFooter && <DefaultFooter />}</div>
      </MainWrapper>
    </Suspense>
  );
};

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <WithScrollToTop />
      <Switch>
        {routes.map((route) => {
          return <RouteDrawer key={route.path} {...route} />;
        })}
        <RouteDrawer path='*' exact={false} component={EmptyPage} />
      </Switch>

      <Modals />
    </BrowserRouter>
  );
};

function ScrollToTop() {
  const history = useHistory();
  useEffect(() => {
    const unbindScroll = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unbindScroll();
    };
  }, []);

  return null;
}

export default Routes;
