import { pxToRem } from 'utils/helpers';
import styled, { createGlobalStyle, css } from 'styled-components';
import { COLORS } from 'styles/colors';
import { VIEWPORTS } from 'styles/responsive.styles';

export const Container = styled.div<{ fluid?: boolean }>`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);

  @media ${VIEWPORTS.Desktop} {
    max-width: ${pxToRem(1440)};
  }

  @media ${VIEWPORTS.Laptop} {
    max-width: ${pxToRem(1440)};
  }

  max-width: ${({ fluid }) => fluid && '100%!important'};
`;

export const Flex = styled.div<{
  wrap?: boolean;
  alignItems?: 'start' | 'end' | 'center';
  justifyContent?: 'space-between' | 'start' | 'center' | 'end';
  gap?: number;
  column?: boolean;
  maxWidth?: string;
}>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap = 0 }) => pxToRem(gap)};
  flex-wrap: ${({ wrap }) => (wrap ? 'wrap' : 'nowrap')};
  max-width: ${({ maxWidth = 'initial' }) => maxWidth};
`;

export const FlexItem = styled.div<{
  justifySelf?: 'start' | 'end';
  width?: string | number;
  margin?: string;
  minWidth?: string;
}>`
  max-width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  justify-self: ${({ justifySelf = 'initial' }) => justifySelf};
  margin: ${({ margin = 'initial' }) => margin};
`;

export const Padding = styled.div<{
  pr?: number;
  pl?: number;
  pb?: number;
  pt?: number;
}>`
  ${({ pr = 0, pt = 0, pb = 0, pl = 0 }) => css`
    padding-top: ${pxToRem(pt)};
    padding-bottom: ${pxToRem(pb)};
    padding-left: ${pxToRem(pl)};
    padding-right: ${pxToRem(pr)};
  `}
`;

export const GlobalStyle = createGlobalStyle`
  :root {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    --default-font: 'Roboto', sans-serif;
    --container: ${pxToRem(1440)};
    --gutter: 0 ${pxToRem(15)};
    --input-radius: ${pxToRem(4)};
    --container-indents: ${pxToRem(30)} ${pxToRem(25)};
  }
  * {
    box-sizing: border-box;
    font-family: var(--default-font) !important;
  }

  html,
  body {
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: var(--default-font);
  }
  hr {
    border: none;
    border-top: 2px solid #E5E5E5;
    margin: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  ul,
  li,
  p {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    font-weight: bolder;
  }
  h1,
  h2,
  h3,
  h4{
    color: ${COLORS.BlueDarkActive};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .slick-track {
    margin-left: 0!important;
  }
  .stop-scrolling {
    overflow: hidden;
  }
  textarea,
  input {
    padding: ${pxToRem(10)} ${pxToRem(15)};
    border-radius: ${pxToRem(4)};
    font-size: ${pxToRem(14)};
    font-family: var(--default-font);
    &::placeholder {
      letter-spacing: ${pxToRem(0.3)}
    }
  }
  .breadcrumbs {
    @media ${VIEWPORTS.Mobile} {
      /* margin-left: 0!important; */
      font-size: ${pxToRem(12)}!important;
      /* margin-top: ${pxToRem(10)}!important; */
    }
  }
  .MuiBackdrop-root {
    background-color: rgba(22, 27, 46, 0.54) !important;
  }
  .MuiMenu-root {
    .MuiBackdrop-root {
      background-color: transparent !important;
    }
  }
  .MuiCheckbox-colorSecondary.Mui-checked, .MuiCheckbox-colorSecondary.MuiCheckbox-indeterminate {
    color: ${COLORS.BlueDark} !important;
  }
  .sq-card-wrapper .sq-card-iframe-container {
    border-radius: 4px !important;
    outline: none;
  }
  .sq-card-wrapper.sq-focus .sq-card-iframe-container::before, .sq-card-wrapper.sq-focus .sq-card-iframe-container {
    border-radius: 4px !important;
    border: solid 2px ${COLORS.BlueDark} !important; 
    outline: none !important;
  }
  .clickable {
    cursor: pointer;
  }
  .swal2-icon {
    width: ${pxToRem(45)};
    height: ${pxToRem(45)};

    .swal2-icon-content {
      font-size: ${pxToRem(35)};
    }
  }

  .swal2-icon.swal2-warning {
    border-color: ${COLORS.Orange};
    color: ${COLORS.Orange};
  }
  .swal2-html-container, h2 {
    color: ${COLORS.Black};
    line-height: 1.5;
  }

  .swal2-actions {
    .swal2-styled.swal2-default-outline:focus {
      box-shadow: none;
    }
    .swal2-styled.swal2-cancel:focus, .swal2-styled.swal2-confirm:focus{
      box-shadow: none;
    }
    button {
      width : auto;
      outline: none;
      box-shadow: none;
      padding-left: ${pxToRem(40)};
      padding-right: ${pxToRem(40)};
    }
    .swal2-styled.swal2-confirm {
      background: ${COLORS.BlueDark} !important;
      border-radius: ${pxToRem(4)};
      border: 1px solid ${COLORS.BlueDark};

      &:hover {
        background: #ffffff !important;
        color: ${COLORS.BlueDark} !important;
        border: 1px solid ${COLORS.BlueDark} !important;
      }
    }
    .swal2-styled.swal2-cancel {
      background: #fff !important;
      border-radius: ${pxToRem(4)};
      border: 1px solid ${COLORS.BlueDark};
      color: ${COLORS.BlueDark};
      min-width: ${pxToRem(130)};

      &:hover {
        background: #ffffff !important;
        color: ${COLORS.BlueDarkActive} !important;
        border: 1px solid ${COLORS.BlueDarkActive} !important;
      }
    }
  }
`;

export const Bold = styled.strong``;
