import styled from 'styled-components';
import { pxToRem } from 'utils/helpers';

export const variants = {
  1: {
    fontSize: `clamp(${pxToRem(40)}, 5vw, ${pxToRem(64)})`,
  },
  2: {
    fontSize: `clamp(${pxToRem(22)}, 5vw, ${pxToRem(40)})`,
  },
  3: {
    fontSize: `clamp(${pxToRem(24)}, 5vw, ${pxToRem(26)})`,
  },
  4: {
    fontSize: `clamp(${pxToRem(20)}, 5vw, ${pxToRem(24)})`,
  },
  5: {
    fontSize: `clamp(${pxToRem(18)}, 5vw, ${pxToRem(20)})`,
  },
  6: {
    fontSize: `clamp(${pxToRem(14)}, 5vw, ${pxToRem(16)})`,
  },
  bold: {
    fontWeight: 700,
  },
};

export const HeadingWrapper = styled.div<{ align: string }>`
  text-align: ${({ align }) => align};
  width: 100%;
`;
