export const COLORS = {
  White: '#ffffff',
  LightGray: '#E3E4E9',
  Black: '#212121',
  Black3: '#262626',
  Gray2: '#E1E1E1',
  Grey5: '#E2E3E4',
  Grey10: '#E3E4E9',
  Grey20: '#CCCCCC',
  Grey30: '#808080',
  Grey60: '#686868',
  DarkGrey: '#908796',
  BlueDark: '#00539B',
  BlueDarkActive: '#1C2455',
  BlueBlack: '#191E3A',
  PlaceholderGrey: '#DEDBDB',
  BorderGrey: '#DFE3EE',
  SidebarBlueWhite: '#F6F7FC',
  LightGrey: '#9DA4B1',
  LighterGrey: '#F6F7FC',
  Orange100: '#F26641',
  Orange: '#EC692A',
  OrangeActive: '#DE5A1B',
  GreyWhite: '#F2F4F9',
  SkyBlue: '#E0F1FF',
  LabelGrey: '#7C7E84',
  Disabled: '#CDD1D7',
  Red: '#EA3C3C',
  Yellow100: '#E08B0E',
  Green10: '#E0EBC9',
  Green20: '#bed099',
  Green30: '#6e8a34',
  Green: '#4caf50',
  GreenDark: '#5B8A51',
  GreenDarker: '#6E8A34',
  GreenLight: '#DCECD0',
  Salad: '#ADC66B',
  SaladActive: '#8b9e5a',
  Milk: `#F5F5F5`,
  Grey100: '#797984',
  Selection: '#F0F8FF',
};
