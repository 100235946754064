import React from 'react';
import { useStyles } from '../Styling/LandingPageHeaderStyles';
import { Grid, AppBar, Toolbar, IconButton, MenuItem, Menu } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { default as RenewIcon } from '@material-ui/icons/Create';
import LockIcon from '@material-ui/icons/Lock';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import { ACBLButton, ACBLMenu, ACBLMenuItem } from '../Styling/ACBL';
import { ACBLButton as FAQButton } from 'uikit/Button';
import { URL } from '../../utils/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { clearProfile } from 'store/profile/reducer';
import { useHistory } from 'react-router';
import { clearAuthCookies } from 'utils/helpers';
import { clearFavorites } from 'store/favorite/reducer';
import { clearRegisteredIds } from '../../store/registeredIds/reducer';
import { Icon } from '../../uikit/Icon';
import useAuthentication from 'helpers/Authentication';
import { modalNames } from '../../store/modals/constants';
import { showModalActionCreator } from '../../store/modals/reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProfileName = () => {
  const profile = useSelector((state: RootState) => state.profile);
  return <div>Welcome, {profile.memberName}!</div>;
};

const LoginButton = ({ classes }: { classes }) => {
  const { login } = useAuthentication();
  return (
    <ACBLButton
      className={classes.navBarButton}
      color='primary'
      variant='contained'
      onClick={login}
    >
      Login
    </ACBLButton>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LandingPageHeader: React.FC<{
  withMenu?: boolean;
}> = ({ withMenu = true }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state: RootState) => state.profile);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen: React.ComponentState = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose: React.ComponentState = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose: React.ComponentState = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const showHelpModal = ({ classes }) => {
    dispatch(
      showModalActionCreator<modalNames.helpModal>({
        name: modalNames.helpModal,
        data: {
          buttonText: 'Close',
          body: (
            <>
              <p className={classes.helpModalPIndent}>
                For your convenience, we`ve compiled a comprehensive document to address frequently
                asked questions.
              </p>
              <FAQButton
                className={classes.entryExpressFlierButton}
                color='primary'
                size='sm'
                onClick={() => window.open(URL.EntryExpressFlier)}
              >
                <span>Entry Express FAQ</span>
              </FAQButton>
              <p className={classes.helpModalPIndent}>
                Should you need further guidance or have any additional questions, our dedicated
                team is here to help. Reach out to us via email at{' '}
                <a className={classes.helpModalEmail} href='mailto:entryexpress@acbl.org'>
                  entryexpress@acbl.org
                </a>
                .
              </p>
            </>
          ),
          title: 'Entry Express FAQ',
        },
      })
    );
  };

  const handleLogout = async () => {
    clearAuthCookies();
    dispatch(clearProfile(null));
    dispatch(clearFavorites());
    dispatch(clearRegisteredIds());
    history.push('/tournaments');
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <ACBLMenu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.navbarMenu}
    >
      <ACBLMenuItem
        onClick={() => (window.location.href = URL.ProfileRedirect as string)}
        className={classes.navbarMenuItem}
      >
        <HomeIcon className={classes.icon} />
        <span className={classes.iconText}>Home</span>
      </ACBLMenuItem>
      <ACBLMenuItem
        onClick={() => (window.location.href = URL.Live as string)}
        className={classes.navbarMenuItem}
      >
        <LiveTvIcon className={classes.icon} />
        <span className={classes.iconText}>Live</span>
      </ACBLMenuItem>
      <ACBLMenuItem
        onClick={() => (window.location.href = URL.Base + '/membership/renewal')}
        className={classes.navbarMenuItem}
      >
        <RenewIcon className={classes.icon} />
        <span className={classes.iconText}>Renew</span>
      </ACBLMenuItem>
      <ACBLMenuItem
        onClick={() => (window.location.href = location.origin + '/tournaments')}
        className={classes.navbarMenuItem}
      >
        <EmojiEvents className={classes.icon} />
        <span className={classes.iconText}>Find Tournament</span>
      </ACBLMenuItem>
      <ACBLMenuItem
        onClick={() => (window.location.href = URL.Base + '/user/update-password')}
        className={classes.navbarMenuItem}
      >
        <LockIcon className={classes.icon} />
        <span className={classes.iconText}>Update Password</span>
      </ACBLMenuItem>
      <ACBLMenuItem onClick={handleLogout} className={classes.navbarMenuItem}>
        <PowerSettingsNewIcon className={classes.icon} />
        <span className={classes.iconText}>Logout</span>
      </ACBLMenuItem>
    </ACBLMenu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div>
        <AppBar position='static'>
          <Grid
            container
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            className={classes.appBarHeader}
          >
            {(profile.memberId || profile.admin_id) && withMenu ? (
              <ACBLButton
                className={classes.navBarButton}
                color='primary'
                variant='contained'
                onClick={handleMenu}
              >
                <ProfileName />
              </ACBLButton>
            ) : (
              <LoginButton classes={classes} />
            )}
          </Grid>
          <Toolbar className={classes.appBar}>
            <Icon
              icon='headerLogo'
              alt='NavBar Logo'
              size={76}
              isComponent
              className={classes.logo}
              onClick={() => window.open('https://www.acbl.org/')}
            />

            <FAQButton
              className={classes.helpModalButton}
              color='primary'
              size='sm'
              onClick={() => showHelpModal({ classes })}
            >
              <HelpOutline />
              <span>Entry Express FAQ</span>
            </FAQButton>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>
    </>
  );
};

export default LandingPageHeader;
