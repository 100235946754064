import { pxToRem } from 'utils/helpers';
import styled from 'styled-components';
import { LoaderPropType } from '../Loader';

export const StyledWrapper = styled.div<LoaderPropType>`
  position: ${(props) => props.position ?? 'fixed'};
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '100%'};
  left: ${(props) => props.left ?? 0};
  right: ${(props) => props.right ?? 0};
  top: ${(props) => props.top ?? 0};
  bottom: ${(props) => props.bottom ?? 0};
  background-color: ${(props) => props.color ?? 'rgba(22, 27, 46, 0.54)'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${(props) => props.zIndex || 999};
  ${(props) => (props.customStyle ? props.customStyle : {})};
`;
export const IconWrapper = styled.div`
  position: absolute;
  top: ${pxToRem(11)};
  left: ${pxToRem(15)};
`;
