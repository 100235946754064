import React from 'react';
import styled from 'styled-components';
import { variant as _variant } from 'styled-system';
import { ButtonWrapper, btnVariants, btnSize } from './Button.styles';
import { TSize, TVariants } from './types';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

export type ButtonProps = {
  variant?: TVariants;
  size?: TSize;
  onClick?: (...props: unknown[]) => void;
  full?: boolean;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean | boolean[];
  badge?: string | number;
  width?: string | number;
  height?: number | string;
  children: React.ReactNode;
  loading?: boolean;
  circleProps?: CircularProgressProps;
  fontSize?: string;
  fontWeight?: string;
  hintText?: string;
};

export const ButtonBase: React.FC<ButtonProps> = ({
  children,
  size = 'sm',
  variant = 'primary',
  type = 'button',
  width,
  onClick: handleClick,
  full = false,
  badge = '',
  disabled = false,
  loading = false,
  circleProps,
  hintText = '',
  height,
  ...props
}) => {
  return (
    <ButtonWrapper
      badge={badge}
      role='button'
      hintText={hintText}
      test-dataid='Button'
      onClick={handleClick}
      btn={variant}
      height={height}
      size={size}
      full={full}
      type={type}
      width={width}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <CircularProgress
          className='loader'
          {...circleProps}
          size={circleProps?.size || 18}
          color={circleProps?.color || 'inherit'}
        />
      ) : null}{' '}
      {children}
    </ButtonWrapper>
  );
};

const ACBLButton = styled(ButtonBase)(
  _variant({
    variants: btnVariants,
    prop: 'variant',
  }),
  _variant({
    variants: btnSize,
    prop: 'size',
  })
);

export default ACBLButton;
