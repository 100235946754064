export const scrollToAnchor = (anchor: string, options?) => {
  if (anchor) {
    try {
      const el = document.querySelector(anchor);
      if (el) el.scrollIntoView(options);
    } catch (e) {
      return null;
    }
  }
};
