import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TournamentEventDataType } from 'utils/Types';
export type TNotEligibleMember = number | string;
type TEntryProps = {
  entry: TournamentEventDataType | null;
  addedMembersForEntry: TNotEligibleMember[];
};

const initialState: TEntryProps = {
  entry: null,
  addedMembersForEntry: [],
};

export const entrySlice = createSlice({
  name: 'entry',
  initialState,
  reducers: {
    setAlreadyIncludeMember: (state, action: PayloadAction<{ member_number: number }>) => {
      return {
        ...state,
        addedMembersForEntry: [action.payload.member_number, ...state.addedMembersForEntry],
      };
    },
    setEntry: (state, action: PayloadAction<{ entry: TournamentEventDataType }>) => {
      return {
        ...state,
        entry: action.payload.entry,
      };
    },
  },
});

export const { setEntry, setAlreadyIncludeMember } = entrySlice.actions;
export { initialState as entryInitialState };
export default entrySlice.reducer;
