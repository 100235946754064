import moment from 'moment';
import { IEnteryInput, IEnteryProps } from 'store/registration/types';
import {
  DEFAULT_SESSION_DURATION_IN_MINUTES,
  FAST_SESSION_DURATION_IN_MINUTES,
  DEFAULT_SESSION_NUMBER,
} from './Constants';
import { ECurrency, ITotalPrice, TournamentEventDataType } from './Types';
import _ from 'lodash';

export const strToAMPM = (str: string) => {
  const [hours, minutes] = str.split(':');
  const convHours = Number(hours) > 12 ? Number(hours) - 12 : Number(hours);
  const AMPM = +hours > 12 && +hours !== 24 ? 'PM' : 'AM';
  return `${convHours}:${minutes} ${AMPM}`;
};

export const handleFormikValue = (value, toNumber?: boolean) =>
  value ? (toNumber ? +value : value) : undefined;

export const handleArrayFormikValue = (data: unknown) => {
  return Array.isArray(data) && data?.filter((item) => !!item)?.length
    ? data
        .map((item) => {
          return item.value ? item.value : item;
        })
        .flat(1)
    : undefined;
};

const roundWithPrecision = (n: number, precision: number): number => {
  const m = 10 ** precision;
  return Math.round(n * m) / m;
};

export const clearAuthCookies = () => {
  document.cookie = 'XSRF-TOKEN=; path=/; domain=.acbl.org; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = 'UserId=; path=/; domain=.acbl.org; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = 'MYACBL=; path=/; domain=.acbl.org; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = 'AuthToken=; path=/; domain=.acbl.org; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie =
    'oauth.code_verifier=; path=/; domain=.acbl.org; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = 'oauth.state=; path=/; domain=.acbl.org; expires=Thu, 01 Jan 1970 00:00:00 GMT';
  window.localStorage.removeItem('oauth.state');
  window.localStorage.removeItem('oauth.code_verifier');
};

export const getAlphabetically = <T>(list: T[], fromBy: string) => {
  return list.sort((a, b) => a[fromBy].localeCompare(b[fromBy]));
};

export const pxToRem = (v: string | number): string | number => {
  const ratio = 16;
  const px = parseFloat(String(v));
  const isRelativemeasurement = /(\%|vh|vw|rem|em|auto)$/.test(String(v)); // return if value is 50% 100vh ...

  if (Number.isNaN(px) || isRelativemeasurement) {
    return v;
  }

  try {
    return `${roundWithPrecision(px / ratio, 4)}rem`;
  } catch (e) {
    return v;
  }
};

export const convertMoneyTypeToNumber = (amount: string | number) => {
  const removedDollarSign = String(amount).replace('$', '');
  return Number(removedDollarSign);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isRegisteredForTheEvent = (events: any[], currentEvent: TournamentEventDataType) => {
  return events?.find((item) => {
    const startDate = currentEvent.StartDate;
    const isSameDate = moment.utc(item.event?.event_date).isSame(moment.utc(startDate), 'date');
    if (Number(currentEvent.SchEventID) === Number(item.event_id) && isSameDate) {
      return true;
    }
  });
};

export const isEventSelected = (events: IEnteryInput[], i: TournamentEventDataType) => {
  return events.find((item) => {
    const startDate = i.StartDate;
    const isSameDate = moment.utc(item.event_date).isSame(moment.utc(startDate), 'date');
    if (Number(i.SchEventID) === Number(item.event_id) && isSameDate) {
      return true;
    }
  });
};

export const isMPLimited = (myTotalMP: number, maxMPLimit: number) => {
  if (myTotalMP > maxMPLimit) {
    return { limited: true, message: 'Too many Masterpoints for this event' };
  } else {
    return { limited: false, message: '' };
  }
};

export function sortEventBySessionTime(a, b) {
  const aTimeArray = a.SessionTime?.split('&').map((i) => moment.utc(i.trim(), ['h:mm A']));
  const bTimeArray = b.SessionTime?.split('&').map((i) => moment.utc(i.trim(), ['h:mm A']));

  if (aTimeArray.length === 2 && bTimeArray.length === 2) {
    const time1 = aTimeArray[0].isSame(bTimeArray[0])
      ? 0
      : aTimeArray[0].isAfter(bTimeArray[0])
        ? 1
        : -1;
    const time2 = aTimeArray[1].isSame(bTimeArray[1])
      ? 0
      : aTimeArray[1].isAfter(bTimeArray[1])
        ? 1
        : -1;

    if (time1 > time2) {
      return -1;
    } else if (time1 < time2) {
      return 1;
    } else {
      return 0;
    }
  } else if (aTimeArray.length === 2 && bTimeArray.length === 1) {
    return aTimeArray[0].isSame(bTimeArray[0]) ? 0 : aTimeArray[0].isAfter(bTimeArray[0]) ? 1 : -1;
  } else {
    return aTimeArray[0].isSame(bTimeArray[0]) ? 0 : aTimeArray[0].isAfter(bTimeArray[0]) ? 1 : -1;
  }
}

export function isRegisteredForFirstSession(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registeredEvents: any[],
  currentEvent: TournamentEventDataType,
  entriesWMultiSessions: TournamentEventDataType[]
): boolean {
  if (!currentEvent.MultiSessionalKo) {
    return false;
  }

  const registeredEventsExpanded: TournamentEventDataType[] = [];
  let currentEventExpanded;

  entriesWMultiSessions.forEach((event) => {
    registeredEvents.forEach((regEvent) => {
      if (Number(regEvent.event_id) === Number(currentEvent.SchEventID)) {
        currentEventExpanded = event;
      }
      if (Number(event.SchEventID) === Number(regEvent.event_id)) {
        registeredEventsExpanded.push(event);
      }
    });
  });

  const isRegisteredFirstEvent = registeredEvents?.find((i) => {
    if (
      Number(i.event_id) === Number(currentEvent.SchEventID) &&
      currentEvent.SessionNumber >= DEFAULT_SESSION_NUMBER
    ) {
      return true;
    }
  });
  if (currentEventExpanded && isRegisteredFirstEvent) {
    const isCurrenEventDate = moment.utc(currentEventExpanded.StartDate).format('Y-MM-DD');
    const today = moment.utc(new Date()).subtract(1, 'days').format('Y-MM-DD');

    const isCurrentEventPast = isCurrenEventDate >= today;
    if (
      registeredEventsExpanded.find(
        (event) =>
          Number(event.EventName) === Number(currentEventExpanded.EventName) && !isCurrentEventPast
      )
    ) {
      return false;
    }
  } else {
    return true;
  }
  return false;
}

export function disableForNonMultiSessionEntrants(
  currentEvent: TournamentEventDataType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registeredEvents: any[],
  entriesWMultiSessions: TournamentEventDataType[]
) {
  const { MultiSessionalKo: isMultiSession, SessionNumber, SchEventID: event_id } = currentEvent;

  const isDisabled: boolean[] = [];
  if (registeredEvents.length > 0) {
    const entriesWMultiSessionIndex = entriesWMultiSessions.findIndex((i) => {
      return event_id === i.SchEventID;
    });

    if (entriesWMultiSessionIndex === -1) {
      return [false];
    }
    const currentEventInfo: TournamentEventDataType =
      entriesWMultiSessions[entriesWMultiSessionIndex];

    const isRegisteredForFirstMultiSession = _.isMatch(registeredEvents, (event) => {
      return event.event_id === event_id && isMultiSession;
    });
    if (
      isRegisteredForFirstMultiSession &&
      isMultiSession &&
      currentEventInfo.SessionNumber === DEFAULT_SESSION_NUMBER
    ) {
      isDisabled.push(false);
    } else if (
      !isRegisteredForFirstMultiSession &&
      isMultiSession &&
      currentEventInfo.SessionNumber === DEFAULT_SESSION_NUMBER
    ) {
      isDisabled.push(false);
    } else if (isMultiSession && SessionNumber > DEFAULT_SESSION_NUMBER) {
      isDisabled.push(true);
    }
  } else if (isMultiSession && SessionNumber > DEFAULT_SESSION_NUMBER) {
    isDisabled.push(true);
  }

  return isDisabled.some((i) => i);
}

export function disabelSimultaniouseEvents(
  currentEvent: TournamentEventDataType,
  registeredEvents: IEnteryProps[]
) {
  // tslint:disable-next-line:variable-name
  const { SessionTime, StartDate, SchEventID: event_id, EventName } = currentEvent;
  const numberOfSessions = SessionTime.split('&').map((i) => i.trim());

  const maxSessionDurationInMinutes = EventName.toLowerCase().includes('fast')
    ? FAST_SESSION_DURATION_IN_MINUTES
    : DEFAULT_SESSION_DURATION_IN_MINUTES;

  // console.log('---', registeredEvents);
  const isDisabled: boolean[] = [];
  registeredEvents?.forEach((item) => {
    const theSameDate = moment.utc(item.event_date).isSame(moment.utc(StartDate), 'date');
    const eventSessions = item.session_time_ampm?.split('&').map((i) => i.trim());

    if (theSameDate && +item.event_id !== +event_id) {
      // isDisabled =
      isDisabled.push(
        !checkIsTimeOverlap(numberOfSessions, eventSessions, maxSessionDurationInMinutes)
      );
    }
  });

  return isDisabled.some((i) => i);
}

function checkIsTimeOverlap(
  currentEvent: string[] = [],
  nextEvent: string[] = [],
  maxDuration: number = DEFAULT_SESSION_DURATION_IN_MINUTES
): boolean {
  const isTimeOverlap = currentEvent
    .map((item) => {
      const start = moment.utc(item, ['h:mm A']);
      return nextEvent.every((item) => {
        const end = moment.utc(item, ['h:mm A']);
        const diffInMinutes = moment.duration(end.diff(start)).asMinutes();
        return Math.abs(diffInMinutes) > maxDuration;
      });
    })
    .every((item) => item === true);

  return isTimeOverlap;
}

export function getURLWithoutParams(url: string) {
  const urlWithoutParams = url.split('?')[0];
  return urlWithoutParams;
}

export const displayPrice = ({ amount, currency = ECurrency.USD }: ITotalPrice) => {
  try {
    const [beforeDot, afterDot] = String(amount).split('.');
    const newAfterDot = afterDot ? afterDot.slice(0, 2) : null;

    const total = String(beforeDot).replace('$', '');
    const currencySign = currency === ECurrency.USD ? '$' : ECurrency.CAD;

    return `${currencySign}${total}${newAfterDot ? '.' + newAfterDot : ''}`;
  } catch (e) {
    return amount;
  }
};

export function getIsJuniorAndIsYouth(
  dateOfBirth: string,
  tournamentStartDate: string
): { isJunior: boolean; isYouth: boolean } {
  const MIN_JUNIOR_AGE = 21;
  const MAX_JUNIOR_AGE = 25;
  const MAX_YOUTH_AGE = 20;

  const tournamentDate: Date = new Date(tournamentStartDate);
  const birthDate: Date = new Date(dateOfBirth);
  let age: number = tournamentDate.getFullYear() - birthDate.getFullYear();
  const month: number = tournamentDate.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && tournamentDate.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  let isJunior = false;
  let isYouth = false;

  if (age >= MIN_JUNIOR_AGE && age <= MAX_JUNIOR_AGE) {
    isJunior = true;
  }
  if (age <= MAX_YOUTH_AGE) {
    isYouth = true;
  }

  return {
    isJunior,
    isYouth,
  };
}

export function fixDateFromDbRegistrationDate(dateString: string): string {
  const tourDate = dateString;
  const tourDateFix = tourDate.split(' ');
  return `${tourDateFix[0]} ${tourDateFix[1]}, ${tourDateFix[5]}`;
}

export function fixDateFromDb(dateString: string): string {
  const tourDate = dateString;
  const tourDateFix = tourDate.split(' ');
  return `${tourDateFix[0]} ${tourDateFix[1]}, ${tourDateFix[4]}`;
}
