import { css } from 'styled-components';

export const slideDown = css`
  .slideDown-enter {
    opacity: 0;
  }
  .slideDown-enter-active {
    opacity: 1;
    transition: all 1s ease;
    transform: translateY(40px);
  }
  .slideDown-exit {
    opacity: 1;
  }
  .slideDown-exit-active {
    opacity: 0;
    transition: all 1s ease;
    transform: translateY(-40px);
  }
`;
