import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Record<string, boolean> = {};

export const registeredIdsSlice = createSlice({
  name: 'registeredIds',
  initialState,
  reducers: {
    saveRegisteredIds: (state, action: PayloadAction<Record<string, boolean>>) => {
      return {
        ...action.payload,
      };
    },
    clearRegisteredIds: () => ({}),
  },
});

export const { saveRegisteredIds, clearRegisteredIds } = registeredIdsSlice.actions;
export { initialState as registeredIdsInitialState };
export default registeredIdsSlice.reducer;
