import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { VIEWPORTS, XL } from 'styles/responsive.styles';
import { pxToRem } from 'utils/helpers';

export const StayingHotelWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  height: 100%;

  .info {
    align-self: end;
    color: ${COLORS.Red};
    font-size: ${pxToRem(14)};
    padding-left: ${pxToRem(10)};
    padding-right: ${pxToRem(10)};
    padding-bottom: ${pxToRem(5)};
    font-weight: 600;
  }

  .warning-message {
    display: block;
    margin-top: ${pxToRem(20)};
  }

  @media (max-width: ${XL}) {
    &.not-staying-at-hostel {
      .date-wrapper {
        display: none;
        height: 0;
        transition: 0.4s;
        margin-bottom: ${pxToRem(-60)};
      }
    }
    &.staying-at-hostel {
      .date-wrapper {
        display: block;
        height: auto;
        transition: 0.4s;
      }
    }
  }
`;

export const CheckboxWrapper = styled.div`
  z-index: 20;
  position: relative;
`;

export const DateInputWrapper = styled.div`
  @media ${VIEWPORTS.Mobile} {
    input {
      font-size: 14px;
    }
  }
`;

export const DateControlsWrapper = styled.div`
  margin: ${pxToRem(50)} 0;
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 0fr auto;
  grid-column-gap: ${pxToRem(11)};
  align-items: center;
`;
export const InputLabel = styled.label`
  font-size: ${pxToRem(14)};
  margin-bottom: ${pxToRem(14)};
  padding-left: ${pxToRem(10)};
  display: flex;
  align-items: center;
`;

export const Label = styled.p`
  padding-left: ${pxToRem(10)};
`;

export const RoomCount = styled.div`
  background-color: ${COLORS.White};
  border-radius: ${pxToRem(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxToRem(37)};
  height: ${pxToRem(37)};
  border: 1px solid rgba(157, 164, 177, 0.5);
`;

export const RoomWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: ${pxToRem(9)};
  width: max-content;
  align-items: center;
`;

export const ErrorMessage = styled.p`
  margin-top: ${pxToRem(10)};
  margin-left: ${pxToRem(10)};
  color: ${COLORS.Red};
  font-weight: 400;
  font-size: ${pxToRem(14)};
`;

export const InputLabelWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: ${pxToRem(30)};
`;
