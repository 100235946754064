import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TournamentDataType } from 'utils/Types';

type TFavoriteProps = {
  cards: {
    [key: number]: TournamentDataType | null;
  };
};

const initialState: TFavoriteProps = {
  cards: {},
};

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {
    save: (state, action: PayloadAction<{ tourId: number; tournament: TournamentDataType }>) => {
      return {
        ...state,
        cards: {
          ...state.cards,
          [action.payload.tourId]: action.payload.tournament,
        },
      };
    },
    unsave: (state, action: PayloadAction<{ tourId: number; tournament: TournamentDataType }>) => {
      delete state.cards[action.payload.tourId];
      return state;
    },
    clearFavorites: () => {
      return initialState;
    },
  },
});

export const { save, unsave, clearFavorites } = favoriteSlice.actions;
export { initialState as favoritesItialState };
export default favoriteSlice.reducer;
