import { useMutation } from '@apollo/client';
import {
  saveGoingPreRegistrationForm,
  saveGoingPreRegistrationFormVariables,
} from '../__generated__/saveGoingPreRegistrationForm';
import { saveGoingPerRegistrationFormMutation } from '../Mutations';

export const useSaveGoingPreRegForm = () => {
  const [fetch, data] = useMutation<
    saveGoingPreRegistrationForm,
    saveGoingPreRegistrationFormVariables
  >(saveGoingPerRegistrationFormMutation);
  return {
    fetch,
    ...data,
  };
};
