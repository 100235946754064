import { gql } from '@apollo/client';

export const getMemberProfile = gql`
  query getUserProfile($member_number: Int!) {
    getUserProfileByMemberNumber(member_number: $member_number) {
      member_number
      first_name
      last_name
      home_phone
      cell_phone
      status
      active
      email
      total_masterpoints
      member_type
    }
  }
`;

export const getTournamentTypes = gql`
  query TournamentTypes {
    TournamentTypes {
      TypeName: type_name
      TypeCode: type_code
      TourTypeID: tour_type_id
    }
  }
`;

export const getGameTypeByTourIdQuery = gql`
  query getGameTypeByTourId($tournamentId: Int!) {
    SchGameTypeByTourId(tournament_id: $tournamentId) {
      GameType: game_type_name
    }
  }
`;

export const getMpLimitsByTourIdQuery = gql`
  query getMpLimitsByTourId($tournamentId: Int!) {
    SchMPLimitByTourId(tournament_id: $tournamentId) {
      MPLimit: mp_limit
    }
  }
`;

export const getTournamentCategories = gql`
  query TournamentCategories {
    TournamentCategories {
      CategoryName: tour_category_name
      TourCatID: tour_category_id
    }
  }
`;

export const getTournamentsWithPaginationQuery = gql`
  query generateTournamentsWithPagination(
    $filter: VTournamentFilters
    $searchText: String
    $limit: Int
    $sortBy: String
    $offset: Int
    $useAdminView: Boolean
  ) {
    SchVTourneysWithPagination(
      useAdminView: $useAdminView
      filter: $filter
      pagination: { offset: $offset, limit: $limit, sortBy: $sortBy, search: $searchText }
    ) {
      total
      list {
        TourID: tour_id
        SiteCity: site_city
        SiteCountry: site_country
        TourDistrict: tour_district
        TourStartDate: tour_start_date
        TourDates: tour_dates
        ScheduleLink: schedule_link
        TourEndDate: tour_end_date
        TourCategory: tour_category_name
        TourVStartDate: tour_start_date
        TourUnit: tour_unit
        TourName: tour_name
        TourOrganisator: site_name
        TourEndDateStatus: tour_end_date_status
        TourGroup: tour_group_name
        TourSanction: sanction
        TourType: tour_type_name
        SiteState: site_state_prov
        Active: active
        Status: status
        Sanction: sanction
      }
    }
  }
`;

export const getInterestedGoingListAdminQuery = gql`
  query getInterestedGoingListAdmin(
    $tour_id: Int
    $pagination: InterestedMembersPagination!
    $filter: InterestedMembersFilter
  ) {
    getInterestedMembersByTourID(tour_id: $tour_id, pagination: $pagination, filter: $filter) {
      list {
        firstName: first_name
        lastName: last_name
        totalMP: total_masterpoints
        hostHotel: host_hotel
        checkIn: host_hotel_check_in_date
        checkOut: host_hotel_check_out_date
      }
      total
    }
  }
`;

export const getTournamentsWithPaginationAdminQuery = gql`
  query generateTournamentsWithPaginationAdmin(
    $filter: VTournamentFilters
    $searchText: String
    $limit: Int
    $sortBy: String
    $offset: Int
    $useAdminView: Boolean
    $direction: String
  ) {
    SchVTourneysWithPagination(
      filter: $filter
      pagination: {
        offset: $offset
        limit: $limit
        sortBy: $sortBy
        search: $searchText
        direction: $direction
      }
      useAdminView: $useAdminView
    ) {
      total
      list {
        Active: active
        Status: status
        TourID: tour_id
        SiteCity: site_city
        SiteCountry: site_country
        TourDistrict: tour_district
        TourStartDate: tour_dates
        TourEndDate: tour_end_date
        TourCategory: tour_category_name
        TourUnit: tour_unit
        TourName: tour_name
        TourOrganisator: site_name
        TourEndDateStatus: tour_end_date_status
        TourGroup: tour_group_name
        TourSanction: sanction
        TourType: tour_type_name
        SiteState: site_state_prov
        RegisteredMembersCount: registered_members_count
        PlaceName: site_address
        RegisteredSessionsCount: registered_entries_count
        SiteName: site_name
      }
    }
  }
`;

export const getSearchTournamentQuery = gql`
  query getSearchTournament($searchText: String) {
    SchVTourneysWithPagination(
      filter: {}
      pagination: { offset: 0, limit: 3, sortBy: "tour_start_date", search: $searchText }
    ) {
      total
      list {
        TourID: tour_id
        SiteCity: site_city
        SiteCountry: site_country
        TourDistrict: tour_district
        TourStartDate: tour_dates
        TourEndDate: tour_end_date
        TourCategory: tour_category_name
        TourUnit: tour_unit
        TourName: tour_name
        SitePhone: site_phone
        TourOrganisator: site_name
        TourGroup: tour_group_name
        TourSanction: sanction
        Address: site_address
        TourEndDateStatus: tour_end_date_status
        TourType: tour_type_name
        SiteState: site_state_prov
      }
    }
  }
`;

export const getTournamentByIdQuery = gql`
  query getTournamentById($tournamentId: Int!, $useAdminView: Boolean) {
    SchVTourneyById(id: $tournamentId, useAdminView: $useAdminView) {
      Active: active
      TourID: tour_id
      Status: status
      SiteCity: site_city
      SiteName: site_name
      SiteCountry: site_country
      HotelName: host_hotel_name
      HotelAddress: host_hotel_address
      HotelCity: host_hotel_city
      HotelCountry: host_hotel_country
      TourDistrict: tour_district
      TourStartDate: tour_dates
      TourVStartDate: tour_start_date
      TourEndDate: tour_end_date
      TourCategory: tour_category_name
      ScheduleLink: schedule_link
      ScheduleFile: schedule_file
      TourUnit: tour_unit
      TourName: tour_name
      SitePhone: site_phone
      Sanction: sanction
      TourOrganisator: site_name
      DirectorChargeName: director_in_charge_name
      PartnershipChairName: partnership_chair_name
      PartnershipChairEmail: partnership_chair_email
      ChairName: chair_name
      ChairEmail: chair_email
      TourGroup: tour_group_name
      TourSanction: sanction
      Address: site_address
      TourType: tour_type_name
      SiteState: site_state_prov
      TourEndDateStatus: tour_end_date_status
      TourMembersCount: registered_members_count
      RegisteredSessionsCount: registered_entries_count
      JuniorDiscount: per_person_discount_junior
      YouthDiscount: per_person_discount_youth
    }
  }
`;

export const getMultiSessionEventsByEventId = gql`
  query getMultiSessionEventsByEventId($events: [Int!]) {
    SchVEventsWithPagination(
      filter: { event_id: $events }
      useMultiSession: true
      pagination: { limit: 100000, offset: 0, sortBy: "session_number" }
    ) {
      total
    }
  }
`;

//
// export const getHostHotelByTourId = gql`
//   query getHostHotelByTourId($tournamentId: Int!) {
//     SchVHostHotelByTourId(tourId: $tournamentId) {
//       HostHotelID: host_hotel_id
//       Active: active
//       TourId: tour_id
//       Status: status
//       Locked: locked
//       HostHotelNum: host_hotel_number
//       HotelAddress1: host_hotel_address1
//       HotelAddress2: host_hotel_address2
//       HotelCity: host_hotel_city
//       HotelMetropolis: host_hotel_metropolis
//       HotelStateProv: host_hotel_state_prov
//       HotelZIPCode: host_hotel_zip_code
//       HotelLatitude: host_hotel_latitude
//       HotelLongitude: host_hotel_longitude
//       HotelCountry: host_hotel_country
//       HotelPhoneLocal: host_hotel_phone_local
//       HotelPhoneNational: host_hotel_phone_national
//       HotelWebHome: host_hotel_web
//       HotelWebBooking: host_hotel_web_booking
//       HotelPromoCode: host_hotel_promo
//       HotelDirections: hotel_directions
//       HotelComments: hotel_comments
//     }
//   }
// `;

export const getEventsByTourIdWithPaginationQuery = gql`
  query getEventsByTourIdWithPagination(
    $filter: VEventFilters
    $offset: Int
    $limit: Int
    $useAdminView: Boolean
    $useMultiSession: Boolean
  ) {
    SchVEventsWithPagination(
      filter: $filter
      useAdminView: $useAdminView
      useMultiSession: $useMultiSession
      pagination: { limit: $limit, offset: $offset, sortBy: "session_date" }
    ) {
      total
      list {
        EventName: event_name
        StartDate: session_date
        SchEventID: event_id
        WarningMessage: warning_message
        SchTourID: tour_id
        EventComment: event_comments
        GameType: game_type_name
        PerPersonMember: per_person_member
        Total: total_fees
        NumberOfParticipants: total_number
        SessionTimeAMPM: session_time_ampm
        SessionTime: session_time
        TotalPlayer: total_number
        Charge5And6: charge_5_and_6
        Allow5And6: allow_5_and_6
        StrataCode: strata_code
        StrataName: strata_name
        MPLimit: mp_limit
        MaxMPLimit: max_mp_strat1
        Active: registration_available
        SessionsToCharge: sessions_to_charge
        MultiSessionalKo: multi_sessional_ko
        SessionNumber: session_number
      }
    }
  }
`;

export const getManagePricingEventsByTourIdQuery = gql`
  query getManagePricingEventsByTourId(
    $filter: VEventFilters
    $offset: Int
    $limit: Int
    $useAdminView: Boolean
  ) {
    SchVEventsWithPagination(
      filter: $filter
      pagination: { limit: $limit, offset: $offset, sortBy: "session_date" }
      useAdminView: $useAdminView
    ) {
      total
      list {
        eventName: event_name
        startDate: session_date
        schEventID: event_id
        schTourID: event_id
        gameType: game_type_name
        perPersonMember: per_person_member
        total: total_fees
        warningMessage: warning_message
        numberOfParticipants: total_number
        sessionTimeAMPM: session_time_ampm
        sessionTime: session_time
        secondSessionTime: session2_time
        totalPlayer: total_number
        charge5And6: charge_5_and_6
        allow5And6: allow_5_and_6
        sessionsToCharge: sessions_to_charge
        strataCode: strata_code
        strataName: strata_name
        mPLimit: mp_limit
      }
    }
  }
`;

export const getTotalEntryPriceForAdminQuery = gql`
  query getTotalEntryPriceForAdmin($events: [TotalEnteryPriceInput]) {
    getTotalEntryPriceForAdmin(events: $events) {
      total
    }
  }
`;

export const getCitiesListQuery = gql`
  query getCitiesList {
    CitiesList {
      SiteCity: site_city
    }
  }
`;

export const getStateListQuery = gql`
  query getStateList {
    StateList {
      SiteState: short_state_name
      LongStateName: long_state_name
      Country: country
    }
  }
`;

export const getUnitListQuery = gql`
  query UnitList($filter: VTournamentFilters, $useAdminView: Boolean) {
    UnitList(filter: $filter, useAdminView: $useAdminView) {
      TourUnit: tour_unit
    }
  }
`;

export const getDistrictListQuery = gql`
  query DistrictList($filter: VTournamentFilters, $useAdminView: Boolean) {
    DistrictList(filter: $filter, useAdminView: $useAdminView) {
      TourDistrict: tour_district
    }
  }
`;

export const getCurrentMemberEventsQuery = gql`
  query myEvents($query: CurrentRegisteredMemberInput) {
    getCurrentMemberRegistrationById(query: $query) {
      event_id
      member_number
      tour_id
      active
      removed
      event {
        event_date
        session_time_ampm
      }
    }
  }
`;

export const getTournamentStratificationQuery = gql`
  query getTournamentStratification($tourId: Int!) {
    TournamentStratification(tour_id: $tourId) {
      StrataCode: strata_code
      StrataName: strata_name
      StrataLetterA: strat_letter1
      StrataLetterB: strat_letter2
      StrataLetterC: strat_letter3
      StrataMaxA: max_mp_strat1
      StrataMaxB: max_mp_strat2
      StrataMaxC: max_mp_strat3
      StrataMinA: min_mp_strat1
      StrataMinB: min_mp_strat2
      StrataMinC: min_mp_strat3
    }
  }
`;

export const validateEntryEventsQuery = gql`
  query validateEntryByMemberNumbers($query: [EntryInput]) {
    validateEntryByMemberNumbers(query: $query) {
      exist
      member_number
      event_id
      isEntryActive
    }
  }
`;

export const addValidMembersQuery = gql`
  query getUserForRegistration($event_id: Int, $member_number: String!, $team: [String!]!) {
    getUserForRegistration(event_id: $event_id, member_number: $member_number, team: $team) {
      firstName: first_name
      lastName: last_name
      memberId: member_number
      memberType: member_type
      email: email
      total_masterpoints
      active
    }
  }
`;

export const getEventsDateByTourIdQuery = gql`
  query getEventsDateByTourId($tour_id: Int) {
    EventDatesByTournamentID(tournament_id: $tour_id) {
      SessionDate: session_date
    }
  }
`;

export const getVolunteersListQuery = gql`
  query getVolunteersList($filter: VolunteerFilter) {
    getVolunteers(filter: $filter) {
      active
      freeGamesCount: free_games_count
      memberNumber: member_number
      redeemedGamesCount: redeemed_games_count
      remainingGamesCount: remaining_games_count
      tournamentFreePlayerId: tournament_free_play_id
      createdAt: created_at

      member {
        firstName: first_name
        lastName: last_name
        totalMP: total_masterpoints
        memberNumber: member_number
      }
    }
  }
`;

export const eventsByTournamentIdQuery = gql`
  query eventsByTournamentID(
    $tournamentId: Int
    $useAdminView: Boolean
    $useMultiSession: Boolean
  ) {
    EventsByTournamentID(
      id: $tournamentId
      useAdminView: $useAdminView
      useMultiSession: $useMultiSession
    ) {
      eventName: event_name
      startDate: session_date
      startTime: session_time
      startTimeAmpm: session_time_ampm
      eventType: event_code
      sessionId: event_id
      gameType: game_type_name
      membersCount: registered_members_count
      registeredSessionsCount: registered_entries_count
      seatingFinalized: seating_finalized
      seatingFinalizedAt: seating_finalized_at
      seatingFinalizedBy: seating_finalized_by
      sessionNumber: session_number
      multiSessionKo: multi_sessional_ko
    }
  }
`;

export const getRegistrationEntryByIdQuery = gql`
  query getRegistrationEntryById($event_id: Int) {
    getRegistrationEntryById(entry_id: $event_id) {
      date: created_at
      paidByCash
      id: registered_entries_id
      tourId: tour_id
      eventDate: event_date
      eventId: event_id
      eventName: event_name
      phoneNumber: phone_number
      status
      refund: refund_status
      amountPaid: total_price
      entryHash: registration_id
      specialRequest: additional_requests
      electricPlug: electric_plug
      assignedSeats: assigned_seats
      doubleEW: double_ew
      seedRequest: seed_request
      registrationEntryId: registered_entries_id
      sessionAmpm: session_time_ampm
      additionalRequests: additional_requests
      seatTypeEntry: entry
      seatTypeGameTable: game_table
      seatTypeTableSeed: table_seed
      seatTypeTeam: team
      participants: list_of_players {
        firstName: first_name
        lastName: last_name
        email
        totalMP: total_masterpoints
        id: registered_participant_id
        memberNumber: member_number
      }
    }
  }
`;

export const getRegistrationEntriesByEventIdQuery = gql`
  query getRegistrationEntriesByEventId($query: RegistrationPagination) {
    getRegistrationEntriesByEventId(query: $query) {
      list {
        qualified
        id: registered_entries_id
        tourId: tour_id
        eventDate: event_date
        eventId: event_id
        eventName: event_name
        phoneNumber: phone_number
        status
        refund: refund_status
        amountPaid: total_price
        entryHash: registration_id
        specialRequest: additional_requests
        electricPlug: electric_plug
        doubleEW: double_ew
        seedRequest: seed_request
        assignedSeats: assigned_seats
        registrationEntryId: registered_entries_id
        sessionAmpm: session_time_ampm
        additionalRequests: additional_requests
        seatTypeEntry: entry
        seatTypeGameTable: game_table
        seatTypeTableSeed: table_seed
        seatTypeTeam: team
        sessionNumber: session_number
        registration {
          date: created_at
          tournamentName: tour_name
          firstName: registering_member_first_name
          lastName: registering_member_last_name
          email: registering_member_email
          memberNumber: registering_member_number
          status
          paymentId: payment_id
        }
        participants {
          firstName: first_name
          lastName: last_name
          email
          totalMP: total_masterpoints
          id: registered_participant_id
          memberNumber: member_number
          memberType: member_type
        }
      }
      total
    }
  }
`;

export const getEventByIdQuery = gql`
  query getEventById($id: Int!, $useAdminView: Boolean) {
    SchVEventById(id: $id, useAdminView: $useAdminView) {
      multiSessionKO: multi_sessional_ko
      name: event_name
      gameType: game_type_name
      SchEventID: event_id
      SchTourID: tour_id
      EventActive: event_active
      id: event_id
      startDate: session_date
      startTime: session_time_ampm
      seatingMethod: seating_method
      tourId: tour_id
      MaxMPLimit: max_mp_strat1
      PerPersonMember: per_person_member
      Total: total_fees
      NumberOfParticipants: total_number
      SessionTime: session_time
      TotalPlayer: total_number
      Charge5And6: charge_5_and_6
      Allow5And6: allow_5_and_6
      StrataCode: strata_code
      StrataName: strata_name
      WarningMessage: warning_message
      MPLimit: mp_limit
      #     this referes to session.active
      Active: active
      SessionsToCharge: sessions_to_charge
      SessionTimeAMPM: session_time_ampm
      StartDate: session_date
      SessionTimeAMPM: session_time_ampm
      EventName: event_name
      GameType: game_type_name
      registeredMembersCount: registered_members_count
      registeredEntriesCount: registered_entries_count
      seatingFinalized: seating_finalized
      seatingFinalizedAt: seating_finalized_at
      seatingFinalizedBy: seating_finalized_by
    }
  }
`;

export const getSessionEventById = gql`
  query sessionEvent(
    $event_id: Int!
    $useAdminView: Boolean
    $useMultiSession: Boolean
    $sessionNumber: Int
  ) {
    SchVEventById(
      id: $event_id
      useAdminView: $useAdminView
      useMultiSession: $useMultiSession
      sessionNumber: $sessionNumber
    ) {
      Charge5and6: charge_5_and_6
      Allow5and6: allow_5_and_6
      name: event_name
      SchEventId: event_id
      SchTourId: tour_id
      gameType: game_type_name
      PerPersonMember: per_person_member
      startDate: session_date
      SessionTime: session_time
      SessionTimeAMPM: session_time_ampm
      Session2Time: session2_time
      SessionsToCharge: sessions_to_charge
      SeatingMethod: seating_method
      RegistrationAvailable: registration_available
      multiSessionKO: multi_sessional_ko
      SessionNumber: session_number
      SeatingFinalized: seating_finalized
      SeatingFinalizedAt: seating_finalized_at
      SeatingFinalizedBy: seating_finalized_by
    }
  }
`;

export const bulkExportQuery = gql`
  query bulkExport($events: [Int!]!) {
    getTournamentBulkInfo(events: $events) {
      charge5and6: charge_5_and_6
      eventId: event_id
      sessionNumber: session_number
      eventName: event_name
      eventGameTypeName: game_type_name
      sessionDate: session_date
      sessionTimeAmPm: session_time_ampm
      seatingMethod: seating_method
      registeredEntries {
        registeredEntriesId: registered_entries_id
        registrationId: registration_id
        assignedSeats: assigned_seats
        doubleEW: double_ew
        seedRequest: seed_request
        electricPlug: electric_plug
        numberOfSessions: number_of_sessions
        additionalRequests: additional_requests
        seatTypeEntry: entry
        amountPaid: total_price
        seatTypeGameTable: game_table
        seatTypeTableSeed: table_seed
        seatTypeTeam: team
        participants {
          firstName: first_name
          lastName: last_name
          id: member_number
          masterPoints: total_masterpoints
          memberNumber: member_number
        }
        registration {
          memberNumber: registering_member_number
          memberName: registering_member_first_name
        }
      }
    }
  }
`;

export const getAutoSuggestQuery = gql`
  query getAutoSuggest($AutocompleteFilters: AutocompleteFilters, $Pagination: Pagination) {
    SchVTourneysAutocomplete(filter: $AutocompleteFilters, pagination: $Pagination) {
      list {
        name: tour_name
        id: tour_id
      }
    }
  }
`;

export const validateTotalEntryPriceQuery = gql`
  query validateTotalEntryPrice($events: [EntryInput]) {
    validateTotalEntryPrice(events: $events) {
      total
      event_id
    }
  }
`;

export const getMyTournamentsQuery = gql`
  query myTournaments($member_number: Int!, $filter: MyRegistartionFilters) {
    getMyRegistrations(member_number: $member_number, filter: $filter) {
      registration_id
      payment_id
      payment_status
      status
      created_at
      tour_id
      total_price
      tour_start_date
      tour_end_date
      tour_name
      tour_type_name
      tour_category_name
      site_name
      site_address
      site_city
      site_state
      site_state_prov
      tour_district
      tour_unit
      registering_member_number
      TourID: tour_id
      SiteCity: site_city
      TourDistrict: tour_district
      TourStartDate: tour_start_date
      TourEndDate: tour_end_date
      TourCategory: tour_category_name
      TourUnit: tour_unit
      TourName: tour_name
      TourOrganisator: site_name
      TourGroup: tour_group_name
      TourSanction: sanction
      TourDates: tour_dates
      Address: site_address
      TourEndDateStatus: tour_end_date_status
      TourType: tour_type_name
      SiteState: site_state_prov
    }
  }
`;

export const getMyTournamentsNamesQuery = gql`
  query myTournamentsNames($member_number: Int!, $filter: MyRegistartionFilters) {
    getMyRegistrations(member_number: $member_number, filter: $filter) {
      tour_name
      tourId: tour_id
    }
  }
`;

export const getEventTimeByTourIdQuery = gql`
  query getEventTimeByTournamentID($tournamentId: Int!) {
    EventTimeByTournamentID(tournament_id: $tournamentId) {
      SessionTime: session_time
    }
  }
`;

export const getMyTournamentsDetailsQuery = gql`
  query myTournamentDetails($member_number: Int!, $tour_id: Int!) {
    getMyRegistrationDetails(member_number: $member_number, tour_id: $tour_id) {
      events {
        event_id
        event_date
        event_name
        session_time_ampm
        electric_plug
        assigned_seats
        double_ew
        seed_request
        additional_requests
        total_price
        registered_entries_id
        game_table
        entry
        team
        table_seed
        event_seating {
          method
          finalized
          finalized_at
          finalized_by
        }
        status
        active
        participants {
          member_number
          total_masterpoints
          first_name
          last_name
          active
        }
        registration {
          registration_id
          total_price
          created_at
          registering_member_number
          registering_member_first_name
          registering_member_last_name
        }
      }
      payments {
        total_price
        payment_id
        id
        created_at
        receipt_url
        currency
      }
      tournament {
        TourID: tour_id
        TourStartDate: tour_dates
        TourVStartDate: tour_start_date
        TourEndDate: tour_end_date
        TourName: tour_name
        TourType: tour_type_name
        TourCategory: tour_category_name
        TourUnit: tour_unit
        TourDistrict: tour_district
        TourCity: site_address
        Sanction: sanction
        SiteCountry: site_country
        tour_start_date
        tour_end_date
        tour_name
        tour_id
        tour_type_name
        tour_category_name
        site_name
        site_address
        site_city
        site_state_prov
        site_phone
        tour_dates
        chair_email
        chair_name
        partnership_chair_name
        partnership_chair_email
        director_in_charge_name
        sponsor_email
      }
    }
  }
`;

export const getSavedTournamentsQuery = gql`
  query getSavedTournament($member_number: Int!, $filter: SavedTournamentsByMemberFilter) {
    getSavedTournaments(member_number: $member_number, filter: $filter) {
      TourID: tour_id
      TourStartDate: tour_start_date
      TourDistrict: tour_district
      TourCategory: tour_category_name
      TourOrganisator: site_name
      TourType: tour_type_name
      SiteCity: site_city
      TourName: tour_name
      TourUnit: tour_unit
      Status: status
      TourVStartDate: tour_start_date
      TourDates: tour_dates
      SiteState: site_state_prov
      Sanction: sanction
    }
  }
`;

export const adminSignInQuery = gql`
  query adminSignIn($email: String!, $password: String!) {
    signInAdmin(email: $email, password: $password) {
      admin {
        admin_id
        email
        first_name
        last_name
        phone
        status
      }
      token
    }
  }
`;

export const resetAdminPasswordQuery = gql`
  query resetPassword($email: String!) {
    resetPassword(email: $email) {
      ok
    }
  }
`;

export const getSearchMembersByNameQuery = gql`
  query getSearchMembers($member: String!, $pagination: InterestedMembersPagination) {
    searchMembers(member: $member, pagination: $pagination) {
      list {
        firstName: first_name
        lastName: last_name
        fullName: member_name
        active
        memberId: member_number
        memberType: member_type
        email
        total_masterpoints
      }
      total
    }
  }
`;

export const getSuggestedMembersQuery = gql`
  query getSuggestedMembers($member_number: Int!) {
    getSuggestedMembers(member_number: $member_number) {
      firstName: first_name
      lastName: last_name
      active
      fullName: member_name
      name: member_name
      total_masterpoints
      totalMasterpoints: total_masterpoints
      memberType: member_type
      memberId: member_number
      memberNumber: member_number
      email
    }
  }
`;

export const getSuggestMembersWithPricingQuery = gql`
  query GetSuggestedMembersWithPricing($memberNumber: Int!, $eventId: Int!, $sessionNumber: Int) {
    getSuggestedMembersWithPricing(
      member_number: $memberNumber
      event_id: $eventId
      session_number: $sessionNumber
    ) {
      suggestedMemberId: suggested_member_id
      member_number
      memberNumber: member_number
      memberId: member_number
      memberType: member_type
      firstName: first_name
      lastName: last_name
      email
      dateOfBirth: date_of_birth
      registeringMemberNumber: registering_member_number
      memberName: member_name
      name: member_name
      memberNumberLifemaster: member_number_lifemaster
      total_masterpoints
      totalMasterpoints: total_masterpoints
      active
      event_entry_information {
        tourId: tour_id
        eventId: event_id
        playerMemberNumber: player_member_number
        dateOfBirth: date_of_birth
        juniorDiscount: junior_discount
        youthDiscount: youth_discount
        discountAmount: discount_amount
        eligibleForFreePlay: eligible_for_free_play
        originalEventPrice: original_event_price
        chargeFor5Or6Players: charge_for_5_or_6_players
        numberOfSessionsToCharge: number_of_sessions_to_charge
      }
      createdAt: created_at
      updatedAt: updated_at
    }
  }
`;

export const getTourStayingHotelInfoQuery = gql`
  query getTourStayingHotelInfo($memberNumber: Int!, $tourId: Int!) {
    getSavedTournamentInfo(member_number: $memberNumber, tour_id: $tourId) {
      hostHotel: host_hotel
      hostHotelCheckInDate: host_hotel_check_in_date
      hostHotelCheckOutDate: host_hotel_check_out_date
      status
      memberNumber: member_number
      tourId: tour_id
    }
  }
`;

export const getTotalPriceQuery = gql`
  query getTotalPrice($event_entry: [EventEntryInput!]!) {
    calculateTotalPrice(event_entry: $event_entry) {
      eventId: event_id
      totalPrice: total_price
    }
  }
`;

export const getInterestedGoingRegisteredStats = gql`
  query getInterestedGoingRegisteredStats($tourId: Int!) {
    StatsTournament(tour_id: $tourId) {
      going {
        total
      }
      interested {
        total
      }
      registered {
        total
      }
    }
  }
`;

export const getRegisteredUniqueMembersQuery = gql`
  query getRegisteredUniqueMembers($tour_id: Int!, $pagination: RegistrationStatsPagination) {
    registrationStatsByTourId(tour_id: $tour_id, pagination: $pagination) {
      members {
        fillName: member_name
        totalMasterpoints: total_masterpoints
        hostHotel: host_hotel
        hostHotelDates: host_hotel_dates
        totalPaid: total_paid
        registeredEntriesCount: registered_entries
        totalPaid: total_paid
        memberId: member_number
      }
      total
      totalPaid
    }
  }
`;
