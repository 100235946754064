import { uniqueId } from 'lodash';
import moment from 'moment';
import { DEFAULT_TOUR_START_DATE_FORMAT } from 'uikit/TournamentCard/constants';
import { TournamentDataType } from './Types';

export const getTournamentAvailableDates = (tournament: TournamentDataType) => {
  const record: { id: string; value: string }[] = [];
  const start = moment(tournament.TourVStartDate).utc();
  const end = moment(tournament.TourEndDate).utc();

  const diff = end.diff(start, 'days');
  for (let d = 0; d <= diff; d++) {
    const date = moment(tournament.TourVStartDate)
      .add(d, 'day')
      .utc()
      .format(DEFAULT_TOUR_START_DATE_FORMAT);
    record.push({
      id: uniqueId(),
      value: date,
    });
  }

  return record;
};
