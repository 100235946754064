/* eslint-disable @typescript-eslint/no-explicit-any */
// import LandingPageHeader from "../components/Headers/LandingPageHeader";
// import DefaultFooter from "../components/Footers/DefaultFooter";
import { Wrapper, Main } from './styles';

const AppLayout = ({ children }: any) => {
  return (
    <Wrapper id='main'>
      {/* <LandingPageHeader /> */}
      <Main>{children}</Main>
      {/* <DefaultFooter /> */}
    </Wrapper>
  );
};

export default AppLayout;
