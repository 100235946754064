export const TOURNAMENTS_PAGE = '/tournaments';
export const HOME_PAGE = '/tournaments';
export const TOURNAMENT_DETAIL_PAGE = HOME_PAGE + '/:id/detail';
export const CLIENT_TOURNAMENTS_SEARCH_PAGE = HOME_PAGE + '/tournament_search';
export const LOGIN_PAGE = '/login';

export const ADMIN_PAGE = '/admin';
export const ADMIN_SIGN_IN = ADMIN_PAGE + '/sign-in';
export const ADMIN_PASSWORD_RESET = ADMIN_PAGE + '/password-reset';
export const MY_TOURNAMENTS_PAGE = '/my-tournaments';
export const MY_TOURNAMENTS_DETAILS_PAGE = '/my-tournaments/:id';
export const TOURNAMENTS_MANAGE_PAGE = ADMIN_PAGE + '/manage_tournaments';
export const TOURNAMENT_MANAGE_PAGE = ADMIN_PAGE + '/manage_tournaments/:id';
export const TOURNAMENT_GAME_PAGE = ADMIN_PAGE + '/manage_tournament_games/:id';
export const TOURNAMENT_PRICING_PAGE = ADMIN_PAGE + '/manage_tournament_pricing/:id';
export const TOURNAMENTS_SEARCH_PAGE = ADMIN_PAGE + '/manage_tournaments_search';
export const PAYMENTS_PAGE = '/payments';
