import { makeStyles, createStyles } from '@material-ui/core';
import { pxToRem } from 'utils/helpers';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: pxToRem(254),
      width: '100%',
      backgroundColor: 'rgb(28, 36, 85)',
    },
    largeGroup: {
      marginTop: pxToRem(1),
      borderTopWidth: pxToRem(4),
      borderTopStyle: 'solid',
      borderColor: 'rgb(244, 179, 27)',
      boxSizing: 'border-box',
      flexGrow: 1,
      minHeight: pxToRem(254),
      width: '100%',
      backgroundColor: 'rgb(28, 36, 85)',
      margin: pxToRem(0),
    },
    footerItem: {
      textAlign: 'center',
      verticalAlign: 'middle',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingRight: '0!important',
        paddingLeft: '0!important',
      },
    },
    acblLogo: {
      height: pxToRem(140),
    },
    baron: {
      height: pxToRem(80),
    },
    navText: {
      fontSize: pxToRem(10),
      color: 'rgb(242, 101, 65)',
    },
    navButtons: {
      padding: pxToRem(8),
      width: `${pxToRem(40)}!important`,
      height: `${pxToRem(40)}!important`,
      backgroundColor: 'rgb(242, 101, 65)',
      fill: 'white!important',
      borderRadius: pxToRem(40),
    },
    navButtonText: {
      color: 'white',
      paddingLeft: pxToRem(10),
    },
    navLinks: {},
  })
);
