import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;

  flex-direction: column;
`;

export const Main = styled.main`
  flex: 1;
  position: relative;
`;
