import { ApolloProvider } from '@apollo/client';
import ErrorNotifier from 'components/ErrorNotifier';
import React from 'react';
import initApollo from './ApolloClient';

export function withApollo<T>(App: React.ComponentType<T>) {
  return (hocProps: T) => {
    return (
      <ErrorNotifier>
        {(showError) => (
          <ApolloProvider client={initApollo(showError)}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <App {...(hocProps as T)} />
          </ApolloProvider>
        )}
      </ErrorNotifier>
    );
  };
}
