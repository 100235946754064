import { makeStyles, createStyles } from '@material-ui/core/styles';
import { pxToRem } from 'utils/helpers';
import { COLORS } from 'styles/colors';

export const useStyles = makeStyles(() =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: '#1c2455',
      height: pxToRem(96),
      paddingLeft: pxToRem(24),
      paddingRight: pxToRem(24),
      justifyContent: 'space-between'
    },
    profileBar: {
      backgroundColor: '#00529b',
      height: pxToRem(36),
      boxShadow: '0 3px 3px rgba(0, 0, 0, 0.38)',
    },
    title: {
      display: 'none',
    },
    search: {
      position: 'relative',
      marginLeft: 0,
      width: '100%',
    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      width: '100%',
    },
    sectionDesktop: {
      display: 'none',
    },
    sectionMobile: {
      display: 'flex',
    },
    hideMailIcon: {
      display: 'none',
    },
    logo: {
      height: '80%',
      cursor: 'pointer',
    },
    appBarHeader: {
      height: pxToRem(40),
      backgroundColor: '#00529b',
    },
    navBarButton: {
      paddingRight: pxToRem(40),
      color: 'white',
      fontSize: pxToRem(15),
      backgroundColor: '#00529b',
      justifyContent: 'end',
    },
    helpModalButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: 'white',
      fontSize: pxToRem(15),
      backgroundColor: '#6AB2C0',
      width: pxToRem(200)
    },
    helpModalPIndent: {
      textIndent: '20px'
    },
    helpModalEmail: {
      color: COLORS.BlueDark
    },
    entryExpressFlierButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      fontSize: pxToRem(15),
      backgroundColor: '#00529b',
      width: pxToRem(100)
    },
    navbarMenu: {
      width: pxToRem(400),
      minWidth: pxToRem(400),
      maxWidth: pxToRem(400),
      marginTop: pxToRem(30),
    },
    navbarMenuItem: {
      width: pxToRem(400),
      minWidth: pxToRem(400),
      maxWidth: pxToRem(400),
      paddingLeft: pxToRem(10),
      color: '#00529b',
    },
    icon: {
      display: 'inline-flex',
      VerticalAlign: 'text-bottom',
      BoxSizing: 'inherit',
      textAlign: 'center',
      AlignItems: 'center',
    },
    iconText: {
      fontSize: pxToRem(13),
    },
  })
);
