import { Suspense } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectModals } from 'store/modals/selectors';
import { components } from './components';
import { GoingPopup } from './GoingPopup/GoingPopup';
import { IGoingForm } from '../../store/modals/types';

const Modals = () => {
  const modals = useAppSelector(selectModals);

  const { goingForm } = modals;
  const goingData = goingForm?.data as IGoingForm;

  return (
    <div>
      <Suspense fallback=''>
        {components.map(({ name, Component }) => {
          const currentModal = modals[name];
          return currentModal ? <Component key={name} /> : null;
        })}
      </Suspense>

      {goingData?.tournament && goingForm?.show && <GoingPopup tournament={goingData.tournament} />}
    </div>
  );
};
export default Modals;
