import { useField } from 'formik';
import uniqueId from 'lodash/uniqueId';
import { useMemo } from 'react';

import { Field, FieldWrapper } from '../FieldWrapper.types';

export const useFieldWrapper = ({
  name,
  messageId,
  value,
  id: userDefinedId,
}: Field): FieldWrapper => {
  const [field, { error, touched }] = useField(name);
  const hasError = useMemo(() => !!error && touched, [touched, error]);
  const msgId = useMemo(
    () => (messageId ? messageId?.toString() : uniqueId(name)),
    [messageId, name]
  );
  const id = useMemo(() => userDefinedId || uniqueId(`id-${name}-`), [userDefinedId, name]);

  return {
    id,
    value,
    hasError,
    messageId: msgId.toString(),
    error,
    field,
  };
};
