import styled from 'styled-components';
import DefaultFooter from '../components/Footers/DefaultFooter';
import LandingPageHeader from 'components/Headers/LandingPageHeader';

const AdminLayout: React.FC = ({ children }) => {
  return (
    <>
      <LandingPageHeader />
      <ChildContainer>{children}</ChildContainer>
      <DefaultFooter />
    </>
  );
};

const ChildContainer = styled.div`
  height: auto;
`;

export default AdminLayout;
