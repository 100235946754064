import { URLType } from './Types';
const { REACT_APP_BASE_URL, REACT_APP_BASE_LOGIN_URL, REACT_APP_GRAPHQL_ENDPOINT } = process.env;

export const URL: URLType = {
  Base: REACT_APP_BASE_URL,
  BaseLogin: REACT_APP_BASE_LOGIN_URL,
  LoginRedirect: `${REACT_APP_BASE_LOGIN_URL}/login?redirect_url=${REACT_APP_BASE_URL}/pre-registration`,
  GraphQLEndpoint: REACT_APP_GRAPHQL_ENDPOINT,
  LogoutUrl: `${REACT_APP_BASE_LOGIN_URL}/logout`,
  ProfileRedirect: `${REACT_APP_BASE_URL}/home`,
  Live: 'https://live.acbl.org/',
  EntryExpressFlier: 'https://web2.acbl.org/documentLibrary/marketing/EntryExpressFlier.pdf'
};

export const FAST_SESSION_DURATION_IN_MINUTES = 2 * 60 + 29;
export const DEFAULT_SESSION_DURATION_IN_MINUTES = 3 * 60 + 29;

export const DEFAULT_SESSION_NUMBER = 1;
export const MIN_PAIRS_PARTICIPANTS = 2;
export const TOTAL_MASTERPOINTS_WEIGHTED_TOTAL = 4;
