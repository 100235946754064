import { CircularProgress } from '@material-ui/core';
import { StyledWrapper } from './styles/styles';

export type LoaderPropType = {
  color?: string;
  width?: number | string;
  height?: number | string;
  backgroundOpacity?: number;
  zIndex?: number;
  left?: number | string;
  right?: number | string;
  top?: number | string;
  bottom?: number | string;
  position?: 'relative' | 'fixed' | 'absolute';
  customStyle?: HTMLStyleElement;
};

interface ILoaderProps extends LoaderPropType {
  loading: boolean;
}

const Loader: React.FC<ILoaderProps> = ({
  loading = false,
  left,
  right,
  top,
  bottom,
  position,
  width,
  height,
  color,
  customStyle,
  zIndex,
}) => {
  if (!loading) {
    return null;
  }

  return (
    <StyledWrapper
      zIndex={zIndex}
      left={left}
      right={right}
      top={top}
      bottom={bottom}
      position={position}
      color={color}
      width={width}
      height={height}
      customStyle={customStyle}
    >
      <CircularProgress />
    </StyledWrapper>
  );
};

export default Loader;
