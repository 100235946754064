import { COLORS } from 'styles/colors';
import { pxToRem } from 'utils/helpers';
import styled, { css } from 'styled-components';
import { TVariants } from './types';
import { VIEWPORTS } from 'styles/responsive.styles';

export const btnVariants: Record<TVariants, Record<string, unknown>> = {
  primary: {
    bg: `${COLORS.BlueDark}`,
    border: `1px solid transparent`,
    '&:active': {
      bg: `${COLORS.BlueDarkActive}`,
    },
    '&:hover': {
      bg: `${COLORS.White}`,
      color: `${COLORS.BlueDark}`,
      border: `1px solid ${COLORS.BlueDark}`,
    },
  },
  success: {
    bg: `${COLORS.Salad}`,
    border: `1px solid transparent`,
    '&:active': {
      bg: `${COLORS.SaladActive}`,
    },
    '&:hover': {
      bg: `${COLORS.White}`,
      color: `${COLORS.SaladActive}!important`,
      border: `1px solid ${COLORS.SaladActive}`,
    },
  },
  orange: {
    bg: `${COLORS.Orange}`,
    border: `1px solid transparent`,
    '&:active': {
      bg: `${COLORS.OrangeActive}`,
    },
    '&:hover': {
      bg: `${COLORS.White}`,
      color: `${COLORS.Orange}`,
      border: `1px solid ${COLORS.Orange}`,
    },
  },
  'default-green': {
    bg: 'transparent',
    color: `${COLORS.GreenDark}`,
  },
  outline: {
    bg: 'none',
    border: `${pxToRem(1)} solid ${COLORS.BlueDark}`,
    color: `${COLORS.BlueDark}`,
    '&:active': {
      bg: `${COLORS.White}`,
      color: `${COLORS.BlueDarkActive}`,
      borderColor: `${COLORS.BlueDarkActive}`,
    },
    '&:hover': {
      boxShadow: 'none',
      color: `${COLORS.BlueDarkActive}`,
      borderColor: `${COLORS.BlueDarkActive}`,
    },
  },
  'outline-orange': {
    bg: 'none',
    border: `${pxToRem(1)} solid ${COLORS.Orange}`,
    color: `${COLORS.Orange}`,
    '&:active': {
      bg: `${COLORS.White}`,
      color: `${COLORS.OrangeActive}`,
      borderColor: `${COLORS.OrangeActive}`,
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  'outline-disabled': {
    bg: 'transparent!important',
    color: `${COLORS.Disabled}!important`,
    fontWeight: 400,
    border: `${pxToRem(1)} solid ${COLORS.Disabled}`,
    '&:hover': {
      color: `${COLORS.Disabled}!important`,
      border: `${pxToRem(1)} solid ${COLORS.Disabled}!important`,
    },
  },
  link: {
    bg: 'transparent!important',
    color: `${COLORS.BlueDark}!important`,
    fontWeight: 400,
    border: `none`,
    '&:hover': {
      boxShadow: 'none',
    },
  },
};

export const btnSize = {
  xs: {
    width: `${pxToRem(87)}`,
    height: `${pxToRem(27)}`,
    fontSize: `clamp(${pxToRem(10)}, ${pxToRem(12)}, ${pxToRem(14)})`,
  },
  sm: {
    padding: `${pxToRem(10)} ${pxToRem(24)}`,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    minWidth: pxToRem(142),
  },
  lg: {
    padding: `${pxToRem(12)} ${pxToRem(30)}`,
    fontSize: pxToRem(20),
    lineHeight: pxToRem(24),
  },
  fully: {
    width: '100%',
    height: '100%',
  },
};

const ButtonPrimitive = styled.button`
  border-radius: ${pxToRem(6)};
  background: transparent;
  color: ${COLORS.White};
  border: none;
  white-space: nowrap;
  /* transition: all 0.2s ease; */
  overflow: hidden;
  cursor: pointer;
  font-size: ${pxToRem(16)};
  font-family: var(--default-font);
  border-radius: ${pxToRem(4)};
  line-height: ${pxToRem(19)};
  font-weight: 500;

  &:hover {
    /* box-shadow: 0px 6px 9px rgba(24, 30, 51, 0.23); */
  }
`;
const withHintText = css<{ hintText: string }>`
  overflow: visible;

  &:hover:before {
    content: '${({ hintText }) => hintText}';
    display: block;
    position: absolute;
    bottom: -${pxToRem(38)};
    z-index: 12;
    right: 0;
    width: max-content;
    background: ${COLORS.White};
    color: ${COLORS.Black};
    border-radius: ${pxToRem(4)};
    border: ${pxToRem(1)} solid ${COLORS.Grey10};
    padding: ${pxToRem(5)};
    font-size: ${pxToRem(12)};
  }
`;
const withBadge = css<{ badge: string | number; btn: string }>`
  overflow: visible;

  &::after {
    content: '${({ badge }) => badge}';
    display: block;
    position: absolute;
    top: -${pxToRem(5)};
    font-weight: 500;
    font-size: ${pxToRem(14)};
    right: -${pxToRem(10)};
    min-width: ${pxToRem(18)};
    padding: ${pxToRem(4)};
    height: ${pxToRem(18)};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: ${pxToRem(50)};
    color: ${COLORS.Orange};
    ${({ btn }) =>
      btn.includes('orange') &&
      css`
        border: ${pxToRem(1)} solid ${COLORS.Orange};
        color: ${COLORS.BlueBlack};
      `}
  }
`;

export const ButtonWrapper = styled(ButtonPrimitive)<{
  disabled?: boolean;
  btn: string;
  height?: number | string;
  size: string;
  full: boolean;
  width?: string | number;
  badge: string | number;
  hintText: string;
  type?: string;
  fontSize?: string;
  fontWeight?: string;
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ width, height }) =>
    width &&
    css`
      width: ${width}!important;
      height: ${height}!important;
    `}
  ${({ hintText }) => !!hintText && withHintText}
  ${({ badge }) => !!badge && withBadge}
  ${({ full }) =>
    full &&
    css`
      width: 100% !important;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${COLORS.White};
      background-color: ${COLORS.Disabled}!important;
      cursor: not-allowed;
      &:hover {
        box-shadow: none;
        border-color: transparent !important;
        color: ${COLORS.White}!important;
      }
    `}
  .loader {
    margin-right: ${pxToRem(7)};
  }

  ${({ fontWeight }) => (fontWeight ? `font-weight: ${fontWeight + ' !important'}` : '')};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize + ' !important'}` : '')};

  ${({ size }) =>
    size == 'lg' &&
    css`
      @media ${VIEWPORTS.Mobile} {
        padding: ${pxToRem(10)} ${pxToRem(24)}!important;
        font-size: ${pxToRem(16)}!important;
        line-height: ${pxToRem(16)}!important;
      }
    `}
`;
