import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ECurrency, ECurrencySymbol, TCountry } from 'utils/Types';

type TProps = {
  currency: ECurrency;
  currencySymbol?: ECurrencySymbol;
};

const initialState: TProps = {
  currency: ECurrency.USD,
  currencySymbol: ECurrencySymbol.$,
};

export const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<{ country: TCountry }>) => {
      const { country } = action.payload;
      const isCanada = country?.toUpperCase().trim() === 'CANADA';
      const currencyType = isCanada ? ECurrency.CAD : ECurrency.USD;
      const currencySymbol = isCanada ? ECurrencySymbol.CAD : ECurrencySymbol.$;

      return {
        currencySymbol,
        currency: currencyType,
      };
    },
  },
});

export const { setCurrency } = currencySlice.actions;
export { initialState as currencyItialState };
export default currencySlice.reducer;
