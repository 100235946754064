import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import registrationReducer from './registration/reducer';
import profileReducer, { profileInitialState } from './profile/reducer';
import favoriteReducer, { favoritesItialState } from './favorite/reducer';
import registeredIdsReducer, { registeredIdsInitialState } from './registeredIds/reducer';
import refetchQueryReducer from './refetchQuery/reducer';
import persistStore from 'redux-persist/es/persistStore';
import logger from 'redux-logger';
import notEligibleMembersReducer, {
  notEligibleMembersInitialState,
} from './notEligibleMembers/reducer';
import modalsReducer, { modalsInitialState } from './modals/reducer';
import entryReducer, { entryInitialState } from './entry/reducer';
import currentViewReducer, { currentViewInitialState } from './currentView/reducer';
import currencyRecucer, { currencyItialState } from './currency/reducer';

const rootReducer = combineReducers({
  currentView: currentViewReducer,
  currency: currencyRecucer,
  registration: registrationReducer,
  profile: profileReducer,
  notEligibleMembers: notEligibleMembersReducer,
  refetchQuery: refetchQueryReducer,
  modals: modalsReducer,
  entry: entryReducer,
  favorite: favoriteReducer,
  registeredIds: registeredIdsReducer,
});

const persistConfig = {
  key: 'root',
  whitelist: ['profile', 'currentView'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: middlewares,
  preloadedState: {
    currency: currencyItialState,
    currentView: currentViewInitialState,
    entry: entryInitialState,
    favorite: favoritesItialState,
    modals: modalsInitialState,
    registeredIds: registeredIdsInitialState,
    refetchQuery: null,
    profile: profileInitialState,
    notEligibleMembers: notEligibleMembersInitialState,
  },
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store as default, persistor };
