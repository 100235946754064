import { pxToRem } from 'utils/helpers';
import styled, { css } from 'styled-components';
import { COLORS } from 'styles/colors';
import { Field } from 'formik';
export const SelectedField = styled(Field)<{ width: number }>`
  border: none;
  width: ${({ width }) => (width ? pxToRem(width) : '100%')};
  font-weight: 500;
  font-size: ${pxToRem(16)};
  outline: none;
  cursor: pointer;

  &:focus {
    border: none;
  }
`;

export const StyledSelect = styled.div<{ open: boolean }>`
  width: 100%;
  height: ${pxToRem(48)};
  background-color: ${COLORS.White};
  border-radius: ${pxToRem(2)};
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${pxToRem(10)};
  padding-right: ${pxToRem(8)};
  z-index: 4;
  position: relative;

  input {
    padding: ${pxToRem(5)};
  }

  ${({ open }) =>
    open &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: ${pxToRem(2)};
      border-top-right-radius: ${pxToRem(2)};

      ${SelectedField} {
        color: ${COLORS.LightGrey};
      }
    `}
`;

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledDropdown = styled.div<{ show: boolean }>`
  width: 100%;
  position: absolute;
  height: ${pxToRem(145)};
  overflow: auto;
  background-color: ${COLORS.White};
  border-bottom-left-radius: ${pxToRem(2)};
  border-bottom-left-radius: ${pxToRem(2)};
  z-index: 5;
  display: ${({ show }) => (!show ? 'none' : 'grid')};
  grid-template-columns: 1fr;
`;
export const DropdownItem = styled.a<{ isActive?: boolean }>`
  font-size: ${pxToRem(16)};
  font-weight: 500;
  cursor: pointer;

  padding: ${pxToRem(15)} ${pxToRem(24)};

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${COLORS.BlueDark};
      color: ${COLORS.White};
    `};

  &:hover {
    background-color: ${COLORS.GreyWhite};
    color: ${COLORS.Black};
  }
`;

export const IconWrapper = styled.div`
  /* position: relative;
  z-index: ; */
`;

export const Placeholder = styled.span`
  color: ${COLORS.LightGrey};
`;
