import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { pxToRem } from 'utils/helpers';

export const Wrapper = styled.div`
  background-color: ${COLORS.BlueDark};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  height: 100%;
`;
export const Message = styled.pre`
  white-space: pre-wrap;
  padding: ${pxToRem(20)};
  line-height: ${pxToRem(25)};
  font-size: ${pxToRem(20)};
`;
