import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TProps = {
  view: 'list' | 'grid';
};

const initialState: TProps = {
  view: 'list',
};

export const currentViewSlice = createSlice({
  name: 'currentView',
  initialState,
  reducers: {
    setCurrentView: (state, action: PayloadAction<{ view: 'list' | 'grid' }>) => {
      return {
        ...state,
        view: action.payload.view,
      };
    },
  },
});

export const { setCurrentView } = currentViewSlice.actions;
export { initialState as currentViewInitialState };
export default currentViewSlice.reducer;
