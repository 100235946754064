import { CalculatePricingByMemberResults } from '../views/ManageTournamentGame/Players/interfaces';

export interface PreRegHostHotel {
  data: { HostHotels: [HostHotelDataType] };
  loading: boolean;
  error: [string] | undefined;
  tourInfo: TournamentDataType;
}

export interface ProcessDataReturnData {
  tournamentID: number;
  tournamentDays: [];
  tournamentSanction: string;
  tournamentName: string;
}

export interface URLType {
  Base: string | undefined;
  BaseLogin: string | undefined;
  LoginRedirect: string | undefined;
  GraphQLEndpoint: string | undefined;
  LogoutUrl: string | undefined;
  ProfileRedirect: string | undefined;
  Live: string | undefined;
  EntryExpressFlier: string;
}

export interface TournamentDataTypeProps {
  SchTourneys: [TournamentDataType];
}

export interface TourRangeType {
  id: number;
  date: Date;
  rawDate: Date;
}

export interface ProfileDataType {
  email: string;
  first_name: string;
  last_name: string;
  event_id: string;
  memberId: number;
  memberName: string;
  phoneNumber?: number;
  total_masterpoints: number;
}

export interface IEntryPriceProps {
  gameType: string;
  perPersonPrice: number;
  numberOfPlayers: number;
  numberOfSessions: number;
  charge5and6Players: boolean;
}

export interface INumberOfPlayers {
  numberOfPlayers: number;
  charge5and6Players: boolean;
  gameType: string;
}

export interface TournamentDataType {
  SchTourID: number;
  DirectorChargeName: string;
  DirectorChargeEmail: string;
  PartnershipChairName: string;
  PartnershipChairEmail: string;
  ScheduleLink: string;
  ScheduleFile: string;
  ChairName: string;
  ChairEmail: string;
  Status: string;
  Locked: string;
  Address: string;
  SchTourNum: string;
  TourID: number;
  TourDateID: number;
  TourDates: string;
  CurrentFlag: number;
  Iteration: number;
  PrevIterID: number;
  EffectiveDate: string;
  TourOrganisator: string;
  TourSanction: string;
  AltSanction: string;
  TourMnemonic: string;
  TourName: string;
  SitePhone: string;
  SiteCity: string;
  SiteCountry: string;
  SiteMetropolis: string;
  SiteState: string;
  HotelName: string;
  HotelAddress: string;
  TourGroup: string;
  TourCategory: string;
  TourType: string;
  TourMPRestrict: string;
  TourStartDate: string;
  TourVStartDate: string;
  TourEndDate: string;
  TourEndDateStatus: string;
  TourDistrict: string;
  TourUnit: string;
  TourConventions: string;
  TourNBO: string;
  TourDisplayStyle: string;
  SessOrder: string;
  STACflag: number;
  STACType: number;
  DICAssignedTDN: number;
  DICASSIGNEDACBL: string;
  AICAssignedTDN: number;
  AICAssignedACBL: string;
  SponsorFlag: number;
  SponsorCode: string;
  SponsorType: string;
  SponsorACBL: string;
  EventCodeFOrmat: string;
  ACBLscorExport: number;
  ChangeSource: string;
  ChangeACBL: string;
  ChngeDate: string;
  ChangeTime: string;
  LastActionComments: string;
  APIChangeDate: string;
  APIChangeTime: string;
  Active: boolean;
  Sanction: number;
}

export interface HostHotelDataType {
  HostHotelID: number;
  Status: string;
  Locked: string;
  HostHotelNum: string;
  AppID: number;
  TourID: number;
  HotelName: string;
  HotelAddress: string;
  HotelAddressSiteCopy: number;
  HotelAddress1: string;
  HotelAddress2: string;
  HotelCity: string;
  HotelMetroplis: string;
  HotelStateProv: string;
  HotelZIPCode: string;
  HotelLatitude: string;
  HotelLongitude: string;
  HotelCountry: string;
  HotelPhoneLocal: string;
  HotelPhoneNational: string;
  DistanceType: string;
  DistanceValue: string;
  HotelWebHome: string;
  HotelWebBooking: string;
  HotelPromoCode: string;
  HotelDirections: string;
  HotelComments: string;
}

export interface ITournamentTemplates {
  bracketed: string;
  pairs: string;
  seeds: string;
  teams: string;
}
export interface IStratification {
  levelNumber: number;
  StrataCode: string;
  StrataName: string;
  StrataLetterA: string;
  StrataLetterB: string;
  StrataLetterC: string;
  StrataMaxA: number;
  StrataMaxB: number;
  StrataMaxC: number;
  StrataMinA: number;
  StrataMinB: number;
  StrataMinC: number;
}
export interface TournamentEventDataType {
  ACBLscoreName: string;
  CharityFlag: number;
  WarningMessage: string;
  EntryFeeNumber: number;
  EventAPICode: string;
  MultiSessionalKo: boolean;
  SessionNumber: number;
  EventCode: string;
  Allow5And6: boolean | null;
  EventCodeDuplicate: number;
  EventCodeFormat: string;
  EventCodeLocked: number;
  EventCodeManual: number;
  SessionTimeAMPM: string;
  SessionTime: string;
  EventComment: string;
  EventConventions: string;
  EventMnemonic: string;
  EventName: string;
  EventType: string;
  GameType: string;
  TotalPlayer: number;
  JuniorsFlag: number;
  KOBracketFlag: number;
  LocationNumber: number;
  Locked: string;
  MPColor: string;
  MPLimit: string;
  MaxMPLimit: number;
  MPRating: string;
  MixedFlag: number;
  PBPModeFlag: number;
  PlaythroughFlag: number;
  QualifierFlag: number;
  SchEventID: number;
  SchEventNum: string;
  SchTourID: number;
  SeedingFlag: number;
  SeniorsFlag: number;
  SessionsCount: number;
  SideGameFalg: number;
  StartDate: string;
  StartSessionCode: string;
  StartTime: string;
  Status: string;
  StrataCode: string;
  Strata: IStratification;
  StrataName: string;
  WomensFlag: number;
  Total: number | string;
  NumberOfParticipants: number;
  SessionsToCharge?: number;
  PerPersonMember: number | string;
  Charge5And6: boolean | null;
  Active: boolean;
}

export interface GameTypeDataType {
  TypeName: string;
  id: string;
}

export interface MPLimitDataType {
  id: string;
  MPLimit: string;
}
export interface SessionDateDataType {
  id: string;
  SessionDate: string;
}
export interface MemberDataType {
  firstName: string;
  lastName: string;
  memberId: number;
  memberNumber?: number;
  memberType: string;
  email: string;
  active: string;
  total_masterpoints: number;
  totalMasterpoints?: number;
  event_entry_information: TMemberPricing;
}

export type TPaymentStatus = {
  error?: string;
  success: boolean;
  showMessage?: boolean;
};

export type TTeamMemberToRegister = {
  isAdmin: boolean;
  name: string;
  memberId: number | string;
  member_number: number | string;
  memberType: string;
  total_masterpoints: number;
  email?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  memberPricing: CalculatePricingByMemberResults;
  event_entry_information: TMemberPricing;
};

export type TMemberPricing = {
  tourId: number | null;
  eventId: number | null;
  playerMemberNumber: number | null;
  dateOfBirth: string;
  juniorDiscount: number | null;
  youthDiscount: number | null;
  discountAmount: number | null;
  eligibleForFreePlay: boolean | null;
  originalEventPrice: number | null;
  numberOfSessionsToCharge: number | null;
  chargeFor5Or6Players: boolean | null;
};

export type TCountry = 'CANADA' | 'MEXICO' | 'UNITED STATES' | string | undefined | null;
export enum ECurrency {
  USD = 'USD',
  CAD = 'CAD',
}

export enum ECurrencySymbol {
  $ = '$',
  CAD = 'CAD',
}

export interface ITotalPrice {
  amount: number | string;
  currency?: ECurrency;
}
