/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  getMemberProfile,
  getMyTournamentsNamesQuery,
  getSavedTournamentsQuery,
} from '../graphql/Queries';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { clearProfile, updateProfile } from 'store/profile/reducer';
import { RootState } from 'store';
import useAuthentication from './Authentication';
import { clearFavorites, save } from '../store/favorite/reducer';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { selectProfile } from '../store/profile/selectors';
import {
  myTournamentsNames,
  myTournamentsNamesVariables,
} from '../graphql/__generated__/myTournamentsNames';
import {
  DateType,
  ESavedTournamentsDateRange,
} from '../graphql/__generated__/graphql-global-types';
import { saveRegisteredIds } from '../store/registeredIds/reducer';

const usePopulateInterestedRegisteredList = () => {
  const { memberId } = useAppSelector(selectProfile);
  const dispatch = useAppDispatch();

  const [fetchRegistered, { data: registeredTours }] = useLazyQuery<
    myTournamentsNames,
    myTournamentsNamesVariables
  >(getMyTournamentsNamesQuery, {
    fetchPolicy: 'no-cache',
    partialRefetch: false,
  });

  const [fetch, { data: SavedTournamentsResponse }] = useLazyQuery(getSavedTournamentsQuery, {
    notifyOnNetworkStatusChange: true,

    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (memberId) {
      fetch({
        variables: {
          member_number: memberId,
          filter: {
            dateRange: ESavedTournamentsDateRange.UPCOMING,
          },
        },
      });

      fetchRegistered({
        variables: {
          member_number: memberId,
          filter: {
            status: DateType.upcoming,
          },
        },
      });
    }
  }, [memberId]);

  useEffect(() => {
    if (SavedTournamentsResponse?.getSavedTournaments?.length) {
      dispatch(clearFavorites());

      SavedTournamentsResponse.getSavedTournaments.forEach((tournament) => {
        dispatch(save({ tourId: tournament.TourID, tournament }));
      });
    }
  }, [SavedTournamentsResponse]);

  useEffect(() => {
    const ids = registeredTours?.getMyRegistrations?.reduce(
      (acc, registered) => ({ ...acc, [registered?.tourId as string]: true }),
      {} as Record<string, boolean>
    );

    if (ids) dispatch(saveRegisteredIds(ids));
  }, [registeredTours]);
};

export function useMemberProfile() {
  // This is based on a trust system from login.acbl.org as our current SSO implementation is not complete.
  // So as long as the UserId and AuthToken cookies are set by login and we get a userProfile back from the API
  // Then we are good.  This does need to change once SSO is implemented correctly.
  //
  const params = new URLSearchParams(window.location.search);
  const { initLogin } = useAuthentication();
  const [cookies] = useCookies();
  const [MemberLoaded, setMemberLoaded] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { memberId = 0, token = '' } = useSelector((state: RootState) => state.profile);
  const memberNumber = Number(cookies.UserId || memberId);
  const { loading, error, data } = useQuery(getMemberProfile, {
    variables: { member_number: memberNumber },
    skip: !memberNumber,
  });

  const code = params.get('code');
  const state = params.get('state');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function init(): Promise<any | string | undefined> {
      if (
        !window.location.href.includes('localhost') &&
        !window.location.href.includes('amplifyapp') &&
        !cookies.AuthToken &&
        !cookies.UserId
      ) {
        setMemberLoaded(false);
        // @ts-ignore
        // window.location.href = process.env.REACT_APP_LOGIN_REDIRECT;
      }

      if (memberId) {
        if (data && !MemberLoaded && !loading && !error) {
          if (data?.getUserProfileByMemberNumber) {
            const {
              first_name,
              last_name,
              email,
              member_number,
              member_type,
              cell_phone,
              home_phone,
              total_masterpoints,
            } = data.getUserProfileByMemberNumber;

            dispatch(
              updateProfile({
                email,
                last_name,
                first_name,
                total_masterpoints,
                memberId: member_number,
                memberType: member_type,
                memberName: `${first_name} ${last_name}`,
                phoneNumber: cell_phone || home_phone || 0,
              })
            );
            setMemberLoaded(true);
          } else if (!token) {
            dispatch(clearProfile(null));
            setMemberLoaded(true);
          }
        }
      } else {
        initLogin();
      }
    }
    init();
  }, [data, cookies, code, state]);

  usePopulateInterestedRegisteredList();

  return { data, loading, error };
}
