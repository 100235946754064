export enum modalNames {
  entryDetails = 'entryDetails',
  infoModal = 'infoModal',
  confirmCancelEntry = 'confirmCancelEntry',
  confirmCancelTournament = 'confirmCancelTournament',
  contactSponsor = 'contactSponsor',
  goingForm = 'goingForm',
  registerEntryManually = 'registerEntryManually',
  registerEntryPayment = 'registerEntryPayment',
  finalizeSeating = 'finalizeSeating',
  chargeQualifiedPlayers = 'chargeQualifiedPlayers',
  helpModal = 'helpModal'
}
