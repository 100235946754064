import React, { memo, useEffect, useMemo, useRef } from 'react';
import { Heading } from 'uikit/Heading';
import { Icon } from 'uikit/Icon';
import useOutsideClickHandler from './hooks/useOutsideClickHandler';

import {
  PopupWrapper,
  PopupOverlay,
  PopupContainer,
  PopupClose,
  PopupHeader,
  PopupBody,
} from './Popup.styles';

type PopupProps = {
  children: React.ReactChild;
  heading?: string;
  width?: number;
  overflow?: string;
  onClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  hideCloseButton?: boolean;
  headingAlign?: 'center' | 'left' | 'right';
};

const Popup: React.FC<PopupProps> = ({
  children,
  onClose,
  shouldCloseOnOverlayClick = true,
  hideCloseButton = true,
  heading,
  overflow = 'auto',
  width = 916,
  headingAlign = 'left',
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const domNode: HTMLElement = useMemo(() => {
    const node = document.createElement('div');
    node.setAttribute('id', 'Popup-root');
    document.body.style.overflowY = 'hidden';
    return node;
  }, []);

  const handleClose = () => {
    if (domNode) {
      document.body.removeChild(domNode);
      document.body.style.overflowY = 'auto';
    }
    onClose();
  };

  useOutsideClickHandler(wrapperRef, handleClose, shouldCloseOnOverlayClick);

  useEffect(() => {
    if (domNode) {
      document.body.appendChild(domNode);
      document.body.style.overflowY = 'hidden';
    }

    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [domNode]);

  return (
    <PopupWrapper>
      <PopupContainer
        role='dialog'
        overflow={overflow}
        aria-label='Popup'
        className='popup-container'
        width={width}
        ref={wrapperRef}
      >
        {heading && (
          <PopupHeader>
            <Heading level={4} align={headingAlign}>
              {heading}
            </Heading>

            {!hideCloseButton && (
              <PopupClose className='popup-close' onClick={handleClose}>
                <Icon icon='cross' alt='cancel' size={14} />
              </PopupClose>
            )}
          </PopupHeader>
        )}
        <PopupBody className='popup-body'>{children}</PopupBody>
      </PopupContainer>
      <PopupOverlay />
    </PopupWrapper>
  );
};
export default memo(Popup) as React.FC<PopupProps>;
