import { createGlobalStyle } from 'styled-components';

import InterRegular from './Inter-Regular.ttf';
import InterMedium from './Inter-Medium.ttf';
import InterThin from './Inter-Thin.ttf';
import InterBold from './Inter-Bold.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: local('Inter Regular'), 
    url(${InterRegular}) format('truetype');
    font-weight: 400;
    font-style: normal
  }
  
  @font-face {
    font-family: 'Inter';
    src: local('Inter Medium'), 
    url(${InterMedium}) format('truetype');
    font-weight: 600;
    font-style: bold
  }
  
  @font-face {
    font-family: 'Inter';
    src: local('Inter Thin'), 
    url(${InterThin}) format('truetype');
    font-weight: 300;
    font-style: normal
  }

  @font-face {
    font-family: 'Inter';
    src: local('Inter Bold'), 
    url(${InterBold}) format('truetype');
    font-weight: 600;
    font-style: normal
  }
`;
