const EXS = '280px';
const XS = '480px';
const MD = '768px';
const LG = '1024px';
const XL = '1280px';

export const VIEWPORTS = {
  ExtraMobile: `(max-width: ${EXS})`,
  Mobile: `(max-width: ${XS})`,
  MobileLandscape: `(min-width: ${parseInt(XS) + 1}px) and (max-width: ${parseInt(MD) - 1}px)`,
  Tablet: `(min-width: ${MD}) and (max-width: ${LG})`,
  TabletLandscape: `(min-width: ${MD}) and (max-width: ${LG}) and (orientation: landscape)`,
  Laptop: `(min-width: ${parseInt(LG) + 1}px) and (max-width: ${XL})`,
  Desktop: `(min-width: ${parseInt(XL) + 1}px)`,
};

export { XS, MD, LG, XL };
